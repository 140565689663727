import Button from "Component/Admin/Button";
import IconButton from "Component/Admin/Button/IconButton";
import RangePicker from "Component/Admin/DatePicker/RangePicker";
import Dropdown from "Component/Admin/Dropdown";
import InputField from "Component/Admin/Input/InputField";
import RadioArray from "Component/Admin/Radio/RadioArray";
import {
  IDateValue,
  IDropdownValue,
  IRadioValue,
  ISearchValue,
} from "Component/Modules/Utils/Config";
import React from "react";
import "./SearchFilter.scss";

export interface IFilter {
  type: "radio" | "search" | "date" | "dropdown";
  name: string;
  item?: any[]; // 검색조건, 날짜조건, 라디오박스 리스트
  value: IDateValue | ISearchValue | IRadioValue | IDropdownValue;
}
interface ISearchFilterProps {
  filterArr: IFilter[];
  onInitFilter?: () => void;
  onSearch: () => void;
}
const SearchFilter: React.FC<ISearchFilterProps> = ({
  filterArr,
  onInitFilter,
  onSearch,
}) => {
  const handleChangeSearchType = (filter: IFilter, value: number) => {
    const filterValue: ISearchValue = filter.value as ISearchValue;
    if (filterValue.onChangeSearchType) {
      filterValue.onChangeSearchType(value);
    }
  };

  const handleChangeText = (filter: IFilter, text: string) => {
    const filterValue: ISearchValue = filter.value as ISearchValue;
    if (filterValue.onChangeText) {
      filterValue.onChangeText(text);
    }
  };

  const handleChangeRadio = (filter: IFilter, value: string) => {
    const filterValue: IRadioValue = filter.value as IRadioValue;
    if (filterValue.onChange) {
      filterValue.onChange(value);
    }
  };

  const handleChangeDate = (
    filter: IFilter,
    type: "start" | "end",
    date: Date | null
  ) => {
    const filterValue: IDateValue = filter.value as IDateValue;
    if (filterValue.onChange) {
      filterValue.onChange(type, date);
    }
  };

  const handleChangeDropdown = (item: IDropdownValue, value: number) => {
    if (item.onChange) {
      item.onChange(value);
    }
  };

  const renderItems = (filter: IFilter) => {
    if (filter.type === "search") {
      const filterValue: ISearchValue = filter.value as ISearchValue;
      return (
        <div className="search-wrapper">
          {filterValue.searchType && filter.item && filter.item.length > 0 && (
            <Dropdown
              items={filter.item}
              value={filterValue.searchType}
              onChange={(value) => handleChangeSearchType(filter, value)}
            />
          )}
          <InputField
            placeholder="Input Keyword..."
            text={filterValue.keyword}
            onChange={(text) => handleChangeText(filter, text)}
          />
        </div>
      );
    } else if (filter.type === "date") {
      const filterValue: IDateValue = filter.value as IDateValue;
      return (
        <div className="range-picker-wrapper">
          <Dropdown
            items={filter.item!}
            value={filterValue.dateType}
            onChange={(value) => handleChangeSearchType(filter, value)}
          />
          <RangePicker
            startDate={filterValue.start}
            endDate={filterValue.end}
            onChange={(type, date) => handleChangeDate(filter, type, date)}
          />
        </div>
      );
    } else if (filter.type === "radio") {
      const filterValue: IRadioValue = filter.value as IRadioValue;
      return (
        <RadioArray
          items={filter.item!}
          value={filterValue.value}
          onChange={(value) => handleChangeRadio(filter, value)}
        />
      );
    } else if (filter.type === "dropdown") {
      const filterValue: IDropdownValue = filter.value as IDropdownValue;
      return (
        <Dropdown
          items={filter.item!}
          width={filterValue.width}
          value={filterValue.value}
          onChange={(value) => handleChangeDropdown(filterValue, value)}
        />
      );
    }
  };

  return (
    <div className="search-filter">
      {filterArr.map((filter, index) => {
        return (
          <div className="row" key={`filter_${index}`}>
            <div className="key">{filter.name}</div>
            <div className="item-area">{renderItems(filter)}</div>
          </div>
        );
      })}
      <div className="button-area">
        <Button color="white" width="120px" onClick={() => onSearch()}>
          Search
        </Button>
        <Button color="white" width="120px" onClick={onInitFilter}>
          Initialize
        </Button>
      </div>
    </div>
  );
};

export default SearchFilter;
