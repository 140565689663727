import CustomModals from "Component/Admin/Modal";
import AxiosUtil from "Component/Modules/API/AxiosUtil";
import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { RecoilRoot } from "recoil";
import "../src/Assets/font/font.scss";
import "../src/Assets/style/_grid.scss";
import "../src/Assets/style/_reset.scss";
import "./App.css";
import RouterWrapper from "./Pages/Routers/RouterWrapper";

function App() {
  return (
    <RecoilRoot>
      <AxiosUtil>
        <BrowserRouter>
          <Route path={"/"} component={RouterWrapper} />
        </BrowserRouter>
        <CustomModals />
      </AxiosUtil>
    </RecoilRoot>
  );
}

export default App;
