import { useEffect } from "react";
import UploadCard from "./UploadCard";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { reorder } from "Utils/DndFunction";

interface IRowMultiUploadField {
    keyId: string;
    title: string;
    fileArr?: (File | null)[];
    urlArr?: { id?: number; img_url: string }[];
    tip?: string;
    onChange: (key: string, index: number, file: File, url: string) => void;
    onDelete: (key: string, index: number) => void;
    onClickPlus: (index: number) => void;
    onClickMinus: (index: number) => void;
    onChangeFileArr: (fileArr: (File | null)[], urlArr: { id?: number; img_url: string }[]) => void;
}

const RowMultiUploadField: React.FC<IRowMultiUploadField> = ({
    keyId,
    title,
    fileArr,
    urlArr,
    tip,
    onChange,
    onDelete,
    onClickPlus,
    onClickMinus,
    onChangeFileArr,
}) => {
    const handleChange = (index: number, file: File, url: string) => {
        if (onChange) {
            onChange(keyId, index, file, url);
        }
    };
    const handleDelete = (index: number) => {
        if (onDelete) {
            onDelete(keyId, index);
        }
    };

    const onDragEnd = (result: any) => {
        // dropped outside the list(리스트 밖으로 드랍한 경우)
        if (!result.destination || !fileArr || !urlArr) {
            return;
        }

        const items = reorder(fileArr, result.source.index, result.destination.index);
        const items2 = reorder(urlArr, result.source.index, result.destination.index);

        onChangeFileArr(items, items2);
    };

    return (
        <div className="row row-auto">
            <div className="item-key">{title}</div>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div className="item-value" {...provided.droppableProps} ref={provided.innerRef}>
                            {fileArr &&
                                fileArr.map((file: File | null, index: number) => (
                                    <Draggable key={index} draggableId={String(index)} index={index}>
                                        {(provided, snapshot) => (
                                            <UploadCard
                                                keyId={keyId}
                                                fileName={file?.name}
                                                src={urlArr && urlArr.length > 0 ? urlArr[index]?.img_url : ""}
                                                provided={provided}
                                                snapshot={snapshot}
                                                listLength={fileArr.length}
                                                onChange={(file, url) => handleChange(index, file, url)}
                                                onDelete={() => handleDelete(index)}
                                                onClickPlus={() => onClickPlus(index)}
                                                onClickMinus={() => onClickMinus(index)}
                                            />
                                        )}
                                    </Draggable>
                                ))}
                            {provided.placeholder}
                            {tip && <div className="tip">{`* ${tip}`}</div>}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
};

export default RowMultiUploadField;
