import { IHeaderRouteMenu } from "Component/Modules/Utils/Config";
import { RouterString } from "Component/Modules/routesString";

const routes: IHeaderRouteMenu[] = [
  {
    to: `${RouterString.AdminWorks}`,
    activeMenu: RouterString.AdminWorks,
    menuName: "Works Mng",
  },
  {
    to: `${RouterString.AdminAccounts}`,
    activeMenu: RouterString.AdminAccounts,
    menuName: "Account Mng",
  },
];

export default routes;
