import withHeader from "Component/Admin/Header";
import { IRouteMenu } from "Component/Modules/Utils/Config";
import SideLeftMenu from "./SideLeftMenu";

function withSideMenu(Component: any, routers: IRouteMenu[]) {
	return withHeader(function renderMenu(props: any) {
		return (
			<SideLeftMenu routers={routers}>
				<Component {...props} />
			</SideLeftMenu>
		);
	});
}

export default withSideMenu;
