import { LangCode } from "Component/Modules/Store/Code.recoil";
import { languageType, PopupProps } from "Pages/Works/types/type";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { getId } from "Utils/GetIdVimeo";
import "./style.scss";

const AllItemsDialog = ({ data }: { data: PopupProps }) => {
    const { t, i18n } = useTranslation();
    const { contents_img, name, des, video_url } = { ...data };
    const [isDimensions, setDimensions] = useState(false);
    const location = useLocation();
    const [langCode, setLangCode] = useRecoilState(LangCode);
    const url = video_url ? (video_url.indexOf("youtu") !== -1 || video_url.indexOf("youtube") !== -1 ? "https://youtube.com/embed/" + getId(video_url) : video_url.indexOf("vimeo") ? video_url.replace("https://vimeo.com/", "https://player.vimeo.com/video/") : video_url) : undefined;

    const onImgLoad = ({ target }: any) => {
        if (target.offsetHeight !== target.offsetWidth) {
            setDimensions(true);
        }
    };

    const renderDescription = () => {
        return (
            <p>
                {langCode === "en" && des.en}
                {langCode === "kr" && des.ko}
            </p>
        );
    };

    return (
        <div className={`AllItemsPopup ${location.search === "?all" ? "firstDialog" : ""}`}>
            <div className={`AllItemsPopup-contents ${url || isDimensions ? "columnItem" : ""}`}>
                <div className="contents-details">
                    <p className="title-en">{name.en}</p>
                    <p className="title-ko">{name.ko}</p>
                    <div className="sub-detail">{renderDescription()}</div>
                </div>
                <div className="AllItemsPopup-inners">
                    <>
                        {url && contents_img.length < 2 ? ( //case 1: vimeo is exist
                            <div className="mainVideo">
                                <iframe id="iMovie" title="mainVideo" name="movieFrame" src={url} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen={true} />
                            </div>
                        ) : (
                            contents_img &&
                            contents_img.map((item: any, index: any) => (
                                <div key={index} style={{ width: "100%", marginBottom: "12px" }}>
                                    <img onLoad={onImgLoad} src={item} alt="" />
                                </div>
                            ))
                        )}
                    </>
                </div>
            </div>
        </div>
    );
};

export default AllItemsDialog;
