export const BO = "/admin";

export const RouterString = {
  Main: `/main`,
  PageNotFound: "/404",
  About: "/about",
  Contact: "/contact",
  Works: "/works",

  WorkAll: "/works/all",
  WorkGraphicDesign: "/works/graphicdesign",
  WorkFineArt: "/works/fineart",
  WorkConceptArt: "/works/conceptart",
  WorkMV: "/works/musicvideo",
  WorkAdvertising: "/works/advertising",

  AdminLogin: BO + "/login",
  AdminWorks: BO + "/works",
  AdminRegistWorks: BO + "/works/regist",
  AdminUpdateWorks: BO + "/works/update",
  AdminAccounts: BO + "/account",
  AdminRegistAccounts: BO + "/account/regist",
  AdminUpdateAccounts: BO + "/account/update",
};
