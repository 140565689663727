import { RouterString } from "Component/Modules/routesString";
import { IRouteMenu } from "Component/Modules/Utils/Config";

const routes: IRouteMenu[] = [
  {
    path: RouterString.AdminAccounts,
    title: "AccountMng",
    deactiveImg: "/Admin/Icons/ico-user.svg",
    activeImg: "/Admin/Icons/ico-user.svg",
    isMainTab: true,
  },
  {
    path: RouterString.AdminAccounts,
    title: "Account",
    isMainTab: false,
  }
];

export default routes;
