import React from "react";
import "./Dialog.scss";
import { Dialog } from "@material-ui/core";

interface IOneButtonDialogProps {
	onClose?: () => void;
	open: boolean;
	noButton?: boolean;
	onClick: () => void;
	dialogHeadText?: string;
	dialogBodyText1?: string;
	dialogBodyText2?: string;
	dialogButtonText?: string;
}
const OneButtonDialog: React.FC<IOneButtonDialogProps> = ({
	open,
	onClose,
	noButton = false,
	onClick,
	dialogBodyText1,
	dialogBodyText2,
	dialogButtonText,
	dialogHeadText
}) => {
	const DEFAULT_HEAD_TEXT = "알림";
	const DEFAULT_RIGHT_TEXT = "확인";

	return (
		<>
			<Dialog className={"one-button-dialog"} maxWidth={"sm"} open={open} onClose={onClose}>
				<div className="closeBtn" onClick={onClose}>
					<div className="closeBar1" />
					<div className="closeBar2" />
				</div>
				<div className="one-button-dialog-container">
					<h1 className="one-button-dialog-h1">{dialogHeadText || DEFAULT_HEAD_TEXT}</h1>
					<div className="one-button-dialog-text">
						<div>{dialogBodyText1 || null}</div>
						<div>{dialogBodyText2 || null}</div>
					</div>
					<div>
						{noButton ? null : (
							<div className="one-button-dialog-button">
								<span onClick={onClick}>
									{dialogButtonText || DEFAULT_RIGHT_TEXT}
								</span>
							</div>
						)}
					</div>
				</div>
			</Dialog>
		</>
	);
};

export default OneButtonDialog;
