import { Modals } from "Component/Modules/Store/Modal.recoil";
import React from "react";
import react, { useEffect } from "react";
import { useRecoilValue } from "recoil";

interface IModalContainerProps {
    children?: React.ReactNode[]
}

const ModalContainer:react.FC<IModalContainerProps> = ({children}) => {
    const openedModal = useRecoilValue(Modals);

    useEffect(()=>{
        document.body.addEventListener("keydown", closeLastModal);
    }, []);

    const closeLastModal = (e: KeyboardEvent) => {
        if(!(e.key === "Escape")){
            return;
        }

        const list: any[] = getVisibleModals();
        const lastModal = list[list.length-1];
        closeIfEscapable(lastModal)();
    }

    const closeIfEscapable = (modal: any) => () => {
        if(modal && modal.props.escapable){
            modal.close();
        }
    }

    const getVisibleModals = () => {
        const modals:any = children;
        const retModals = openedModal.map((item: any, index: number) => {
            const key = item.key;
            const params = { id: item.id, message: item.message, callback: item.callback};
            const element = modals.filter((m: any)=>m.key === key);
            return react.cloneElement(element[0], {
                key: item.id,
                ...params
            })
        });

        return retModals;
    }
    return (
        <div id="modal-container">
            {getVisibleModals().map(modal => (
                <div key={modal.key} className="modal-background">
                    {modal}
                </div>
            ))}
        </div>
    )
}

export default ModalContainer;