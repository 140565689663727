import Button from "Component/Admin/Button";
import PageTitle from "Component/Admin/PageTitle";
import withSideMenu from "Component/Admin/SideMenu";
import { RouterString } from "Component/Modules/routesString";
import { AlertModal } from "Component/Modules/Store/Modal.recoil";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import routes from "../Routes";
import "Pages/Admin/Common.style.scss";
import WorkService from "Component/Modules/API/Services/Work.service";
import { CategoryCode, ClassificationCode } from "Component/Modules/Store/Code.recoil";
import { IGetDetailWork, IReqCreateWork, IReqUpdateWork } from "Component/Modules/API/Interfaces/Work.interface";
import { ValidCreateWork, ValidUpdateWork } from "Component/Modules/Utils/Validation";
import RowTextField from "./RowTextField";
import RowUploadField from "./RowUploadField";
import RowMultiUploadField from "./RowMultiUploadField";
import RowDropdownField from "./RowDropdownField";
import Loading from "Component/Admin/Loading";
import RowMultiLineField from "./RowTextMutiline";

interface IPutWorks {
    isRegist: boolean;
}

const PutWorks: React.FC<IPutWorks> = ({ isRegist }) => {
    const [request, setRequest] = useState<IReqCreateWork | IReqUpdateWork>({
        titleKor: "",
        titleEng: "",
        shortTitleKor: "",
        shortTitleEng: "",
        categoryId: 0,
        classificationId: 0,
        thumbnailFile: null,
        subThumbnailFile: null,
        imageFileArr: [null],
    });
    const [deleteImages, setDeleteImages] = useState<number[]>([]);
    const [detail, setDetail] = useState<IGetDetailWork>({
        thumbnailUrl: "",
        subThumbnailUrl: "",
        imageArr: [{ id: undefined, img_url: "" }],
    });
    const [loading, setLoading] = useState<boolean>(false);

    const alertModal = useSetRecoilState(AlertModal);
    const categoryCodes = useRecoilValue(CategoryCode);
    const classificationCodes = useRecoilValue(ClassificationCode);

    const history = useHistory();
    const location = useLocation();
    const state: any = location.state;

    useEffect(() => {
        if (!isRegist && state) {
            getVideoDetail(state.id);
        }
    }, []);

    const getVideoDetail = async (id: number) => {
        setLoading(true);
        WorkService.GetWork(id)
            .then((response) => {
                if (response && response.code === "OK200") {
                    const {
                        id,
                        title_kor,
                        title_eng,
                        short_title_kor,
                        short_title_eng,
                        description_kor,
                        description_eng,
                        category_id,
                        classification_id,
                        year,
                        thumbnail_url,
                        sub_thumbnail_url,
                        images,
                        video_url,
                    } = response.data;

                    setRequest({
                        id,
                        titleKor: title_kor,
                        titleEng: title_eng,
                        shortTitleKor: short_title_kor,
                        shortTitleEng: short_title_eng,
                        descriptionKor: description_kor,
                        descriptionEng: description_eng,
                        categoryId: category_id,
                        classificationId: classification_id,
                        year: year,
                        videoUrl: video_url,
                        thumbnailFile: null,
                        subThumbnailFile: null,
                        imageFileArr: images && images.length > 0 ? images.map((img: any) => null) : [null],
                    });
                    setDetail({
                        thumbnailUrl: thumbnail_url,
                        subThumbnailUrl: sub_thumbnail_url,
                        imageArr: images && images.length > 0 ? images : [{ id: undefined, img_url: "" }],
                    });
                    setLoading(false);
                }
            })
            .catch((e) => {
                alertModal({
                    message: e.response.data.message,
                    callback: () => {
                        setLoading(false);
                    },
                });
            });
    };

    const handleClickCancel = () => {
        history.goBack();
    };

    const handleClickUpdate = async () => {
        const data: IReqUpdateWork = request as IReqUpdateWork;

        if (data.id > 0) {
            const validCheck = ValidUpdateWork(data, detail);
            if (!validCheck.isValid) {
                return alertModal({ message: validCheck.message });
            }
            const upsert = detail.imageArr.map((image, index: number) => {
                return { id: image.id, imageFile: data.imageFileArr![index] };
            });
            data.upsert = upsert;
            data.deleteIds = deleteImages;

            setLoading(true);
            const response = await WorkService.UpdateWork(data);
            if (response && response.code === "OK200") {
                alertModal({
                    message: "수정되었습니다.",
                    callback: () => {
                        setLoading(false);
                        history.push(RouterString.AdminWorks);
                    },
                });
            }
        } else {
            alertModal({
                message: "정보를 수정하기 위한 식별자가 유효하지 않습니다.",
            });
        }
    };

    const handleClickRegist = async () => {
        const validCheck = ValidCreateWork(request);
        if (!validCheck.isValid) {
            return alertModal({ message: validCheck.message });
        }
        try {
            setLoading(true);
            const response = await WorkService.PostCreateWork(request);
            if (response && response.code === "OK200") {
                alertModal({
                    message: "생성되었습니다.",
                    callback: () => {
                        setLoading(false);
                        history.push(RouterString.AdminWorks);
                    },
                });
            }
        } catch (e: any) {
            alertModal({
                message: e.response.data.message,
                callback: () => {
                    setLoading(false);
                },
            });
        }
    };

    const handleChangeInput = (key: string, value: any) => {
        setRequest({ ...request, [key]: value });
    };
    const handleChangeFile = (key: string, file: File, url: string) => {
        setRequest({ ...request, [key]: file });
        const key2 =
            key === "thumbnailFile" ? "thumbnailUrl" : key === "subThumbnailFile" ? "subThumbnailUrl" : "imageUrl";
        setDetail({ ...detail, [key2]: url });
    };
    const handleDeleteFile = (key: string) => {
        const newRequest = request;
        if (key === "thumbnailFile") {
            newRequest.thumbnailFile = null;
        } else if (key === "subThumbnailFile") {
            newRequest.subThumbnailFile = null;
        }
        setRequest({ ...newRequest });
        const key2 =
            key === "thumbnailFile" ? "thumbnailUrl" : key === "subThumbnailFile" ? "subThumbnailUrl" : "imageUrl";
        setDetail({ ...detail, [key2]: null });
    };
    const handleChangeFileByIndex = (key: string, index: number, file: File, url: string) => {
        if (key === "imageFile") {
            if (!request.imageFileArr) {
                return;
            }

            let list: (File | null)[] = request.imageFileArr;
            list[index] = file;
            setRequest({ ...request, imageFileArr: [...list] });
            const changedImageArr = detail.imageArr.map((image, idx: number) => {
                if (idx === index) {
                    return { ...image, img_url: url };
                } else return image;
            });

            setDetail({ ...detail, imageArr: [...changedImageArr] });
        }
    };

    const handleDeleteFileByIndex = (key: string, index: number) => {
        if (key === "imageFile") {
            if (!request.imageFileArr) {
                return;
            }
            const deleteTargets: number[] = [];
            const changedImageArr = detail.imageArr.map((image, idx: number) => {
                if (idx == index) {
                    if (image.id) {
                        deleteTargets.push(image.id);
                    }
                    return { id: undefined, img_url: "" };
                } else return image;
            });
            setDeleteImages([...deleteImages.concat(deleteTargets)]);
            setDetail({ ...detail, imageArr: [...changedImageArr] });
        }
    };
    const handleClickPlus = (index: number) => {
        request.imageFileArr?.splice(index + 1, 0, null);
        setRequest({ ...request, imageFileArr: [...request.imageFileArr!] });
        detail.imageArr?.splice(index + 1, 0, { id: undefined, img_url: "" });
        setDetail({ ...detail, imageArr: [...detail.imageArr!] });
    };
    const handleClickMinus = (index: number) => {
        request.imageFileArr?.splice(index, 1);
        setRequest({ ...request, imageFileArr: [...request.imageFileArr!] });
        const deleteTarget = detail.imageArr[index].id;

        if (deleteTarget) {
            setDeleteImages([...deleteImages, deleteTarget]);
        }

        detail.imageArr?.splice(index, 1);
        setDetail({ ...detail, imageArr: [...detail.imageArr!] });
    };

    const handleChangeFileArr = (fileArr: (File | null)[], urlArr: { id?: number; img_url: string }[]) => {
        setRequest({ ...request, imageFileArr: [...fileArr] });
        setDetail({ ...detail, imageArr: [...urlArr] });
    };

    const renderTitle = () => {
        const title = isRegist ? "Create Work" : "Update Work";
        return <PageTitle title={title} />;
    };

    const renderButtonArea = () => {
        if (isRegist) {
            return (
                <div className="button-area">
                    <Button width="100px" onClick={handleClickRegist}>
                        Create
                    </Button>
                    <Button width="100px" color="white" onClick={handleClickCancel}>
                        Cancel
                    </Button>
                </div>
            );
        } else {
            return (
                <div className="button-area">
                    <Button width="100px" onClick={handleClickUpdate}>
                        Update
                    </Button>
                    <Button width="100px" color="white" onClick={handleClickCancel}>
                        Cancel
                    </Button>
                </div>
            );
        }
    };

    return (
        <Loading spinning={loading}>
            <div className="admin-works-put-wrapper">
                {renderTitle()}
                <div className="detail-body">
                    <div className="input-grid-area">
                        <RowDropdownField
                            keyId="categoryId"
                            title="Category *"
                            items={categoryCodes}
                            value={request.categoryId}
                            onChange={handleChangeInput}
                        />
                        <RowDropdownField
                            keyId="classificationId"
                            title="Classification *"
                            items={classificationCodes}
                            value={request.classificationId}
                            onChange={handleChangeInput}
                        />
                        <RowTextField
                            keyId="shortTitleEng"
                            title="Side Title EN *"
                            text={request.shortTitleEng}
                            placeholder="ex: X ARA - 'Hitchhiker' M/V"
                            onChange={handleChangeInput}
                        />
                        <RowTextField
                            keyId="shortTitleKor"
                            title="Side Title KR *"
                            text={request.shortTitleKor}
                            placeholder="ex: 엑스아라 - '히치하이커' 뮤직비디오"
                            onChange={handleChangeInput}
                        />
                        <RowTextField
                            keyId="titleEng"
                            title="Title EN *"
                            text={request.titleEng}
                            placeholder="ex: Oopsee | X ARA - 'Hitchhiker' M/V"
                            onChange={handleChangeInput}
                        />
                        <RowTextField
                            keyId="titleKor"
                            title="Title KR *"
                            text={request.titleKor}
                            placeholder="ex: 웁시 | 엑스아라 - '히치하이커' 뮤직비디오"
                            onChange={handleChangeInput}
                        />
                        <RowTextField
                            keyId="year"
                            title="Year"
                            text={request.year ? request.year : ""}
                            placeholder="ex: 2022"
                            onChange={handleChangeInput}
                        />
                        <RowMultiLineField
                            keyId="descriptionEng"
                            title="Description EN"
                            text={request.descriptionEng ? request.descriptionEng : ""}
                            placeholder="ex: Samsung Digital Plaza | AI Character"
                            onChange={handleChangeInput}
                        />
                        <RowMultiLineField
                            keyId="descriptionKor"
                            title="Description KR"
                            text={request.descriptionKor ? request.descriptionKor : ""}
                            placeholder="ex: 디지털 프라자 | AI 캐릭터"
                            onChange={handleChangeInput}
                        />
                        <RowUploadField
                            keyId="thumbnailFile"
                            title="Thumbnail *"
                            subTitle="1000x1000"
                            file={request.thumbnailFile}
                            url={detail.thumbnailUrl}
                            tip="Only jpg, png, and jpeg can be uploaded"
                            onChange={handleChangeFile}
                            onDelete={handleDeleteFile}
                        />
                        <RowUploadField
                            keyId="subThumbnailFile"
                            title="Thumbnail *"
                            subTitle="1920x1080"
                            file={request.subThumbnailFile}
                            url={detail.subThumbnailUrl}
                            tip="Only jpg, png, and jpeg can be uploaded"
                            onChange={handleChangeFile}
                            onDelete={handleDeleteFile}
                        />
                        <RowMultiUploadField
                            keyId="imageFile"
                            title="Image"
                            fileArr={request.imageFileArr}
                            urlArr={detail.imageArr}
                            tip="Only jpg, png, and jpeg can be uploaded"
                            onChange={handleChangeFileByIndex}
                            onDelete={handleDeleteFileByIndex}
                            onClickPlus={handleClickPlus}
                            onClickMinus={handleClickMinus}
                            onChangeFileArr={handleChangeFileArr}
                        />
                        <RowTextField
                            keyId="videoUrl"
                            title="Video"
                            text={request.videoUrl ? request.videoUrl : ""}
                            placeholder="ex: https://vimeo.com/toastcanvas"
                            onChange={handleChangeInput}
                        />
                    </div>
                </div>
                {renderButtonArea()}
            </div>
        </Loading>
    );
};

export default withSideMenu(PutWorks, routes);
