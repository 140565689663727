import { AxiosRequestConfig } from "axios";
import { IResponse, post, VER } from "../AxiosUtil";
import { ILoginInfo } from "../Interfaces/Auth.interface";

const POST_LOGIN = VER + "/auth/login";

class AuthService {
  Login = async (
    data: ILoginInfo,
    config?: AxiosRequestConfig
  ): Promise<IResponse> => {
    try {
      const response = await post(POST_LOGIN, data, {
        ...config,
      });
      return response?.data;
    } catch (e: any) {
      throw e;
    }
  };
}

export default new AuthService();
