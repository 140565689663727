import { Cookies } from "react-cookie";

const cookies = new Cookies();

const setUserToken = (token: string) => {
	const expire = new Date();
	expire.setHours(new Date().getHours() + 10);

	cookies.set("token", token, {
		path: "/",
		expires: expire
	});
};

const getUserToken = () => {
	return cookies.get("token");
};

const resetUserToken = () => {
	return cookies.remove("token", {
		path: "/"
	});
};

const setRefreshToken = (token: string) => {
	const expire = new Date();
	expire.setHours(new Date().getHours() + 10);

	cookies.set("refresh-token", token, {
		path: "/",
		expires: expire
	});
};

const getRefreshToken = () => {
	return cookies.get("refresh-token");
};

const resetRefreshToken = () => {
	return cookies.remove("refresh-token", {
		path: "/"
	});
};

export {
	setUserToken,
	getUserToken,
	resetUserToken,
	setRefreshToken,
	getRefreshToken,
	resetRefreshToken
};
