import { atom, DefaultValue, selector } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

const UserAccountState = atom({
	key: "userAccount",
	default: {
		id: null,
		username: null,
		role: null
	},
	effects_UNSTABLE: [persistAtom] // Phrases stored in local storage.
});

// 아이디 저장 (로그인 유지)
const AuthenticatedState = atom({
	key: "authenticated",
	default: false,
	effects_UNSTABLE: [persistAtom]
});

interface INotificationOpt {
	enable: boolean;
	complete?: boolean;
	message: string;
}

const NotificationState = atom<INotificationOpt>({
	key: "notification",
	default: {
		enable: false,
		complete: false,
		message: ""
	},
	effects_UNSTABLE: [persistAtom]
});


// eslint-disable-next-line import/prefer-default-export
export {
	UserAccountState,
	AuthenticatedState,
	NotificationState
};
