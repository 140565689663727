import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import React, { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { useHistory } from "react-router-dom";
import { RouterString } from "../routesString";
import { UserAccountState } from "../Store/Common.recoil";
import { resetUserToken } from "../Store/Cookie";
import CommonService from "./Services/Common.service";
import { CategoryCode, ClassificationCode } from "../Store/Code.recoil";

const VER = "/api/v1";

const apiClient: AxiosInstance = axios.create({
  baseURL: "https://server.xoxoxuxu.com:8000", //"https://server.xoxoxuxu.com:8000",
  timeout: 1000 * 60 * 10,
});

type AxiosUtilProps = {
  children: any;
};

interface IResponse {
  code: string | number;
  message: string;
  data?: any;
}
const defaultResponse = {
  code: 400,
  message: "유효하지 않은 통신입니다.",
};

const AxiosUtil: React.FC<AxiosUtilProps> = ({ children }) => {
  const userAccountState = useSetRecoilState(UserAccountState);
  const setCategoryCode = useSetRecoilState(CategoryCode);
  const setClassiciationCode = useSetRecoilState(ClassificationCode);

  const history = useHistory();

  useEffect(() => {
    handleLoaded();
  }, []);

  const handleLoaded = async () => {
    const list = await CommonService.GetCategoryList();
    if (list && list.length > 0) {
      setCategoryCode(list);
    }
    const list2 = await CommonService.GetClassificationList();
    if (list2 && list2.length > 0) {
      setClassiciationCode(list2);
    }
  };

  apiClient.interceptors.request.use(async (request: any) => {
    request.headers = {
      ...request.headers,
    };

    return request;
  });

  apiClient.interceptors.response.use(
    async (response: AxiosResponse) => {
      // if (response.config.url?.indexOf("/auth/login") !== -1) {
      //   const list = await CommonService.GetCategoryList();
      //   if (list && list.length > 0) {
      //     setCategoryCode(list);
      //   }
      //   const list2 = await CommonService.GetClassificationList();
      //   if (list2 && list2.length > 0) {
      //     setClassiciationCode(list2);
      //   }
      // }
      return Promise.resolve(response);
    },
    async (error: AxiosError) => {
      if (error.response) {
        const response = error.response as AxiosResponse<IResponse, any>;
        if (response.data.code === 401) {
          userAccountState(false);
          resetUserToken();
          history.push(RouterString.AdminLogin);
        }
      }

      return Promise.reject(error);
    }
  );

  return children;
};

const { get, post, put, delete: destroy, patch } = apiClient;
export default AxiosUtil;

export { get, post, put, destroy, VER, defaultResponse, patch };
export type { IResponse };
