import axios from 'axios'

const baseURL = 'https://cdn.contentful.com'

class WorksServive {
  getWorks = async () => {
    const { data } = await axios.get(
      `${baseURL}/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/environments/${process.env.REACT_APP_CONTENTFUL_ENVIRONMENT_ID}/entries`,
      {
        params: {
          access_token: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
          content_type: process.env.REACT_APP_CONTENTFUL_CONTENT_TYPE,
          select: 'fields,sys.id',
        },
      },
    )
    let resultList = []
    const items = data.items
    const includedAssets = data.includes.Asset
    const includedEntry = data.includes.Entry

    resultList = items.map((e) => {
      const images = e.fields.images
        ?.map((item) => item.sys.id)
        .map((item) => {
          return includedAssets.find((itemB) => itemB.sys.id === item)?.fields
            .file.url
        })
      const thumbnail = includedAssets.find(
        (item) => item.sys.id === e.fields.thumbnail.sys.id,
      )
      const subThumbnail = includedAssets.find(
        (item) => item.sys.id === e.fields.subThumbnail.sys.id,
      )
      const category = includedEntry.find(
        (item) => item.sys.id === e.fields.category.sys.id,
      )
      const subCategory = includedEntry.find(
        (item) => item.sys.id === e.fields.subCategory.sys.id,
      )

      return {
        id: e.sys.id,
        category: category.fields.name,
        sub_category: subCategory.fields.name,
        year: e.fields.year,
        name: { en: e.fields.titleEn, ko: e.fields.titleKo },
        short_name: { en: e.fields.titleEn, ko: e.fields.titleKo },
        des: { en: e.fields.titleEn, ko: e.fields.titleKo },
        titleEn: e.fields.titleEn,
        titleKo: e.fields.titleKo,
        thumbnail: thumbnail.fields.file.url,
        sub_thumbnail: subThumbnail.fields.file.url,
        contents_img: images,
        videoNew: e.fields.video,
        video_url: e.fields.video,
      }
    })

    return resultList
  }

  getCategorys = async () => {
    try {
      const { data } = await axios.get(
        `${baseURL}/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/environments/${process.env.REACT_APP_CONTENTFUL_ENVIRONMENT_ID}/entries`,
        {
          params: {
            access_token: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
            content_type: 'category',
            select: 'fields,sys.id',
            'metadata.tags.sys.id[in]':
              process.env.REACT_APP_CONTENTFUL_CONTENT_TYPE,
          },
        },
      )
      return data
    } catch (error) {
      console.log(error)
    }
  }
  getSubCategorys = async () => {
    try {
      const { data } = await axios.get(
        `${baseURL}/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/environments/${process.env.REACT_APP_CONTENTFUL_ENVIRONMENT_ID}/entries`,
        {
          params: {
            access_token: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
            content_type: 'subCategory',
            select: 'fields,sys.id',
            // 'metadata.tags.sys.id[in]':
            //   process.env.REACT_APP_CONTENTFUL_CONTENT_TYPE,
          },
        },
      )
      return data
    } catch (error) {
      console.log(error)
    }
  }
}

export default new WorksServive()
