import { Dialog } from "@mui/material";
import Button from "Component/Admin/Button";
import { DeleteModal } from "Component/Modules/Store/Modal.recoil";
import react, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import "./Modal.scss";

interface IModalAlertProps {
    id?: string;
    key: string;
    message?: string;
    escapable?: boolean;
    callback?: ()=>void
}

const ModalAlert:react.FC<IModalAlertProps> = ({id, key, message, escapable, callback}) => {
    const [visible, setVisible] = useState(false);
    const deleteModal = useSetRecoilState(DeleteModal);

    useEffect(()=>{
        setVisible(true);
    }, [])

    const handleOk = () => {
        setVisible(false);
        deleteModal({ id, message: message! });
        if(callback){
            callback();
        }
    }

    const handleClose = () => {
        setVisible(false);
    }

    return (
        <div>
            <Dialog 
                key={id}
                open={visible} 
                onClose={handleClose}
                className="alert-modal"
            >
                <p className="modal-content">{message}</p>
                <div className="button-area">
                    <Button onClick={handleOk}>확인</Button>
                </div>
            </Dialog>
        </div>
    )
}

export default ModalAlert;