import React, { useEffect, useState } from "react";
import PageTitle from "Component/Admin/PageTitle";
import PaginationWrap from "Component/Admin/Pagination";
import SearchFilter, { IFilter } from "Component/Admin/SearchFilter";
import { toYYYYMMDD } from "Component/Modules/Utils/Function";
import { useHistory } from "react-router-dom";
import Button from "Component/Admin/Button";
import DataGrid from "Component/Admin/DataGrid";
import { GridColumns } from "@mui/x-data-grid";
import withSideMenu from "Component/Admin/SideMenu";
import { IRadioValue, ISearchValue } from "Component/Modules/Utils/Config";
import { RouterString } from "Component/Modules/routesString";
import routes from "./Routes";
import "Pages/Admin/Common.style.scss";
import AccountService from "Component/Modules/API/Services/Account.service";
import { useSetRecoilState } from "recoil";
import { AlertModal } from "Component/Modules/Store/Modal.recoil";
import Loading from "Component/Admin/Loading";

interface IAccountManagement {}
const AdminAccountsManagement: React.FC<IAccountManagement> = () => {
  const history = useHistory();
  const [request, setRequest] = useState({
    keyword: "",
    page: 1,
    limit: 20,
    enable: 1,
  });
  const [list, setList] = useState<any[]>([]);
  const [total, setTotal] = useState(100);
  const [selectionRows, setSelectionRows] = useState<number[]>([]);
  const alertModal = useSetRecoilState(AlertModal);
  const [loading, setLoading] = useState<boolean>(false);

  const filterArr: IFilter[] = [
    {
      type: "search",
      name: "Search",
      value: {
        keyword: request.keyword,
        onChangeText: (text: string) =>
          setRequest({ ...request, keyword: text }),
      } as ISearchValue,
    },
    {
      type: "radio",
      name: "Enable",
      item: ["N", "Y", "ALL"],
      value: {
        value: String(request.enable),
        onChange: (value: string) => {
          setRequest({ ...request, enable: Number(value) });
        },
      } as IRadioValue,
    },
  ];

  const columns: GridColumns<any> = [
    {
      field: "index",
      headerName: "No.",
      width: 50,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "username",
      headerName: "ID",
      align: "center",
      headerAlign: "center",
      sortable: false,
      width: 500,
      renderCell: ({ id, value }) => (
        <span
          className="clickable"
          onClick={() => {
            history.push(RouterString.AdminUpdateAccounts, { id });
          }}
        >
          {value}
        </span>
      ),
      flex: 1,
    },
    {
      field: "name",
      headerName: "name",
      align: "center",
      headerAlign: "center",
      sortable: false,
      flex: 1,
    },
    {
      field: "enable",
      headerName: "Enable(Y / N)",
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: ({ value, id }) => <span>{value === "N" ? "N" : "Y"}</span>,
      flex: 1,
    },
    {
      field: "create_dt",
      headerName: "CreateDate",
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: ({ value }) => <span>{value ? toYYYYMMDD(value) : "-"}</span>,
      flex: 1,
    },
    {
      field: "update_dt",
      headerName: "UpdateDate",
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: ({ value }) => <span>{value ? toYYYYMMDD(value) : "-"}</span>,
      flex: 1,
    },
  ];

  useEffect(() => {
    getAccountList();
  }, []);

  const handleInitFilter = () => {
    setRequest({
      ...request,
      keyword: "",
      page: 1,
    });
  };

  const handleSearch = async () => {
    getAccountList();
  };
  const getAccountList = async () => {
    const data = {
      page: request.page,
      limit: request.limit,
      keyword: request.keyword,
      enable: request.enable === 2 ? undefined : request.enable,
    };

    setLoading(true);
    const response = await AccountService.GetAccountList(data);
    if (response && response.code === "OK200") {
      const isExist = response.data.accounts;
      const data = response.data.accounts?.map((item: any, index: number) => {
        return { ...item, index: (request.page - 1) * 20 + index + 1 };
      });
      setList(isExist ? data : []);
      setTotal(isExist ? response.data.total : 0);
      setLoading(false);
    }
  };
  const deleteAccount = async () => {
    const ids = selectionRows.map((item) => item);
    if (ids.length === 0) {
      alertModal({ message: "At least one data must be selected." });
      return;
    }
    try {
      setLoading(true);
      const response = await AccountService.deleteAdmin({ ids });
      getAccountList();
      setLoading(false);
    } catch (error: any) {
      alertModal({
        message: error.response.data.message,
        callback: () => setLoading(false),
      });
    }
  };

  const renderGridButton = () => {
    return (
      <div className="button-area">
        <Button
          color="white"
          width="100px"
          onClick={() => history.push(RouterString.AdminRegistAccounts)}
        >
          Create
        </Button>
        <Button color="white" width="100px" onClick={deleteAccount}>
          Delete
        </Button>
      </div>
    );
  };

  return (
    <Loading spinning={loading}>
      <div className="admin-works-wrapper">
        <PageTitle title="Accounts" />
        <SearchFilter
          filterArr={filterArr}
          onInitFilter={handleInitFilter}
          onSearch={handleSearch}
        />
        <div className="data-grid-area">
          <DataGrid
            data={list}
            columns={columns}
            limit={request.limit}
            total={total}
            isCheckBox
            onSelectionRows={setSelectionRows}
            renderButtonArea={renderGridButton}
          ></DataGrid>
          <PaginationWrap
            currentPage={request.page}
            totalCount={total}
            limit={request.limit}
            onPageChange={(page) => setRequest({ ...request, page })}
          />
        </div>
      </div>
    </Loading>
  );
};

export default withSideMenu(AdminAccountsManagement, routes);
