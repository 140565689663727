import { getItemStyle } from "Utils/DndFunction";
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";

interface OrderCardProps {
    item: any;
    provided: DraggableProvided;
    snapshot: DraggableStateSnapshot;
}
export default function OrderCard({ item, provided, snapshot }: OrderCardProps) {
    if (!item) return null;

    return (
        <div
            ref={provided.innerRef}
            className="order-card"
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
            <span className="index">{item?.index}</span>
            <span className="name">{item?.name.ko}</span>
            <img src={item?.thumbnail} alt="thumbnail" />
        </div>
    );
}
