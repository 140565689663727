import React from "react";
import "./PageTitle.scss";

interface IPageTitleProps {
	title: string;
	subTitle?: string;
}
const PageTitle: React.FC<IPageTitleProps> = ({ title, subTitle }) => {
	return (
		<div className="page-title">
			<div className="page-title-main">{title}</div>
			<div className="page-title-sub">{subTitle}</div>
		</div>
	);
};

export default PageTitle;
