import React, { useEffect, useState } from "react";
import "./TextUnderline.scss";
import styled from "styled-components";
import useWindowSize from "Utils/useWindowSize/useWindowSize";

interface TextUnderlineProps {
  style?: React.CSSProperties;
  borderheight?: number;
  children: any;
  bottom?: number;
  className?: string;
  href?: string;
}

function TextUnderline(props: TextUnderlineProps) {
  const { width } = useWindowSize();
  const { children, bottom, className, href, style, borderheight } = props;
  const [styleHeading, setStyleHeading] = useState({
    fontSize: `5.4rem`,
    fontFamily: "$montserrat",
    fontWeight: "600",
    lineHeight: "1.1",
    color: "#040404",
  });
  const [borderHeighHeading, setBorderHeading] = useState(5);
  const [styleUnderline1, setStyleUnderline1] = useState<any>({
    fontSize: `2.4rem`,
    fontFamily: "$montserrat",
    marginRight: "10px",
    fontWeight: "400",
    marginBottom: "10px",
    lineHeight: "0.5",
  });
  const [borderHeight1, setBorderHeight1] = useState<number>(0.5);
  useEffect(() => {
    if (width > 1279) {
      setStyleHeading({
        fontSize: `5.4rem`,
        fontFamily: "$montserrat",
        fontWeight: "600",
        lineHeight: "1.1",
        color: "#040404",
      });
      setBorderHeading(5);
      setStyleUnderline1({
        fontSize: `2.4rem`,
        fontFamily: "$montserrat",
        marginRight: "5px",
        fontWeight: "400",
        marginBottom: "10px",
        lineHeight: "0.5",
      });
      setBorderHeight1(0.5);
    }
    if (width <= 1279 && width > 768) {
      setStyleHeading({
        ...styleHeading,
        fontFamily: "$montserrat",
        fontWeight: "600",
        lineHeight: "1.1",
        color: "#040404",
      });
      setBorderHeading(5);
      setStyleUnderline1({
        ...styleUnderline1,
        fontFamily: "$montserrat",
        marginRight: "5px",
        fontWeight: "400",
        marginBottom: "10px",
        lineHeight: "0.5",
      });
      setBorderHeight1(0.5);
    } else if (width <= 768 && width > 480) {
      setStyleHeading({
        ...styleHeading,
        fontFamily: "$montserrat",
        fontWeight: "600",
        lineHeight: "1.1",
        color: "#040404",
      });
      setBorderHeading(5);
      setStyleUnderline1({
        ...styleUnderline1,
        fontFamily: "$montserrat",
        marginRight: "5px",
        fontWeight: "400",
        marginBottom: "10px",
        lineHeight: "0.5",
      });
      setBorderHeight1(0.5);
    } else if (width <= 480 && width > 320) {
      setStyleHeading({
        ...styleHeading,
        fontFamily: "$montserrat",
        fontWeight: "600",
        lineHeight: "1.1",
        color: "#040404",
      });
      setBorderHeading(5);
      setStyleUnderline1({
        ...styleUnderline1,
        fontFamily: "$montserrat",
        marginRight: "5px",
        fontWeight: "400",
        marginBottom: "10px",
        lineHeight: "0.5",
      });
      setBorderHeight1(0.5);
    } else if (width <= 320) {
      setStyleHeading({
        ...styleHeading,
        fontFamily: "$montserrat",
        fontWeight: "600",
        lineHeight: "1.1",
        color: "#040404",
      });
      setBorderHeading(5);
      setStyleUnderline1({
        ...styleUnderline1,
        fontFamily: "$montserrat",
        marginRight: "5px",
        fontWeight: "400",
        marginBottom: "10px",
        lineHeight: "0.5",
      });
      setBorderHeight1(0.5);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);
  if (href) {
    <Text
      style={style || styleHeading}
      borderheight={borderheight || borderHeighHeading}
      bottom={bottom}
      className={className}
      href={href}
    >
      {children}
    </Text>;
  }
  return (
    <Text
      style={style || styleHeading}
      borderheight={borderheight || borderHeighHeading}
      bottom={bottom}
      className={className}
    >
      {children}
    </Text>
  );
}

const Text = styled.span<{ borderheight: any; bottom: any; href?: any }>`
  font-size: 54px;
  line-height: 64px;
  font-weight: 600;
  color: #111111;
  position: relative;
  display: inline-block;

  &::after {
    content: "";
    position: absolute;
    bottom: ${({ bottom }) => (bottom ? `${bottom}px` : "-5px")};
    left: 0;
    right: 0;
    height: ${({ borderheight }) =>
      borderheight ? `${borderheight}px` : "5px"};
    background-color: ${(props) =>
      props.style?.color ? props.style.color : "#111111"};
  }
`;

const Link = styled.a<{ borderheight: any; bottom: any }>`
  font-size: 54px;
  line-height: 64px;
  font-weight: 600;
  color: #111111;
  position: relative;
  display: inline-block;

  &::after {
    content: "";
    position: absolute;
    bottom: ${({ bottom }) => (bottom ? `${bottom}px` : "-5px")};
    left: 0;
    right: 0;
    height: ${({ borderheight }) =>
      borderheight ? `${borderheight}px` : "5px"};
    background-color: ${(props) =>
      props.style?.color ? props.style.color : "#111111"};
  }
`;

export default TextUnderline;
