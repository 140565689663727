import {
  FormControl,
  InputBase,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";
import styled from "styled-components";
import "./Dropdown.scss";

const DropdownWrapper = styled(InputBase)(({ theme }) => ({
  width: "100px",
  height: "32px",
  "& .MuiInputBase-input": {
    borderRadius: 2,
    position: "relative",
    border: "1px solid #ced4da",
    fontSize: 14,
    paddingLeft: "11px",
    fontFamily: ["AppleSDGothicNeo"].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#f5f5f5",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

interface IDropdownProps {
  items: {id: number, label: string}[];
  value: number;
  width?: number;
  height?: number;
  onChange: (value: number) => void;
}
const Dropdown: React.FC<IDropdownProps> = ({
  items,
  value,
  width = 100,
  height = 20,
  onChange,
}) => {
  const handleChange = (e: SelectChangeEvent<number>) => {
    if (onChange) {
      onChange(e.target.value as number);
    }
  };
  return (
    <div>
      <FormControl
        variant="standard"
      >
        <Select
          value={value}
          onChange={handleChange}
          input={<DropdownWrapper />}
          style={{ width: width + "px", height: height + "px" }}
        >
          {items.map((item) => (
            <MenuItem key={`${item.id}_${item.label}`}value={item.id}>{item.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default Dropdown;
