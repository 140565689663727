import React, { useEffect, useState } from "react";
import PageTitle from "Component/Admin/PageTitle";
import PaginationWrap from "Component/Admin/Pagination";
import SearchFilter, { IFilter } from "Component/Admin/SearchFilter";
import { toYYYYMMDD } from "Component/Modules/Utils/Function";
import { useHistory } from "react-router-dom";
import Button from "Component/Admin/Button";
import DataGrid from "Component/Admin/DataGrid";
import { GridColumns } from "@mui/x-data-grid";
import withSideMenu from "Component/Admin/SideMenu";
import { IDropdownValue, ISearchValue } from "Component/Modules/Utils/Config";
import { RouterString } from "Component/Modules/routesString";
import routes from "./Routes";
import "Pages/Admin/Common.style.scss";
import WorkService from "Component/Modules/API/Services/Work.service";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { AlertModal } from "Component/Modules/Store/Modal.recoil";
import { CategoryCode, ClassificationCode } from "Component/Modules/Store/Code.recoil";
import Loading from "Component/Admin/Loading";
import MenuButton from "Component/Admin/Button/MenuButton";
import ChangeOrderPopup from "./ChangeOrderPopup";

interface IWorksManagement {}
const AdminWorksManagement: React.FC<IWorksManagement> = () => {
    const history = useHistory();
    const [request, setRequest] = useState({
        category: 0,
        classification: 0,
        keyword: "",
        page: 1,
    });
    const [list, setList] = useState<any[]>([]);
    const [total, setTotal] = useState(100);
    const [selectionRows, setSelectionRows] = useState<any[]>([]);
    const alertModal = useSetRecoilState(AlertModal);
    const categoryCodes = useRecoilValue(CategoryCode);
    const classificationCodes = useRecoilValue(ClassificationCode);
    const [loading, setLoading] = useState<boolean>(false);
    const [openChangeOrder, setOpenChangeOrder] = useState<{
        open: boolean;
        category?: number;
        classification?: number;
    }>({ open: false });

    useEffect(() => {
        getWorkerList();
    }, []);

    const getWorkerList = (req?: { category?: number; classification?: number; keyword?: string; page?: number }) => {
        const requestParams = { ...request };
        if (req?.category !== undefined) {
            requestParams.category = req.category;
        }
        if (req?.classification !== undefined) {
            requestParams.classification = req.classification;
        }
        if (req?.keyword !== undefined) {
            requestParams.keyword = req.keyword;
        }
        if (req?.page !== undefined) {
            requestParams.page = req.page;
        }

        setLoading(true);
        WorkService.GetWorkList(requestParams)
            .then((response) => {
                if (response && response.code === "OK200") {
                    const list = response.data.works.map((item: any, index: number) => {
                        return {
                            ...item,
                            index: (requestParams.page - 1) * 20 + index + 1,
                        };
                    });
                    setList(list);
                    setTotal(response.data.total);
                    setLoading(false);
                }
            })
            .catch((e) => {
                alertModal({
                    message: e.response.data.message,
                    callback: () => {
                        setLoading(false);
                    },
                });
            });
    };

    const filterArr: IFilter[] = [
        {
            type: "dropdown",
            name: "Category",
            item: categoryCodes,
            value: {
                value: request.category,
                width: 200,
                onChange: (value: number) => {
                    setRequest({ ...request, category: value });
                },
            } as IDropdownValue,
        },
        {
            type: "dropdown",
            name: "Classification",
            item: classificationCodes,
            value: {
                value: request.classification,
                width: 200,
                onChange: (value: number) => {
                    setRequest({ ...request, classification: value });
                },
            } as IDropdownValue,
        },
        {
            type: "search",
            name: "Search",
            value: {
                keyword: request.keyword,
                onChangeText: (text: string) => setRequest({ ...request, keyword: text }),
            } as ISearchValue,
        },
    ];

    const columns: GridColumns<any> = [
        {
            field: "index",
            headerName: "No.",
            width: 50,
            align: "center",
            headerAlign: "center",
            sortable: false,
        },
        {
            field: "thumbnail_url",
            headerName: "Thumbnail",
            align: "center",
            headerAlign: "center",
            sortable: false,
            renderCell: ({ value }) => <img className="thumbnail" src={value} alt="thumbnail" />,
            flex: 1,
        },
        {
            field: "title_kor",
            headerName: "Title(Korean)",
            align: "center",
            headerAlign: "center",
            sortable: false,
            renderCell: ({ id, value }) => (
                <span
                    className="clickable"
                    onClick={() => {
                        history.push(RouterString.AdminUpdateWorks, { id });
                    }}
                >
                    {value}
                </span>
            ),
            flex: 1,
        },
        {
            field: "title_eng",
            headerName: "Title(English)",
            align: "center",
            headerAlign: "center",
            sortable: false,
            flex: 1,
        },
        {
            field: "order_by_category",
            headerName: "Order(By Cate)",
            align: "center",
            headerAlign: "center",
            sortable: false,
            flex: 1,
        },
        {
            field: "order_by_classification",
            headerName: "Order(By Class)",
            align: "center",
            headerAlign: "center",
            sortable: false,
            flex: 1,
        },
        {
            field: "create_dt",
            headerName: "Create Date",
            align: "center",
            headerAlign: "center",
            sortable: false,
            renderCell: ({ value }) => <span>{value ? toYYYYMMDD(value) : "-"}</span>,
            flex: 1,
        },
        {
            field: "update_dt",
            headerName: "Update Date",
            align: "center",
            headerAlign: "center",
            sortable: false,
            renderCell: ({ value }) => <span>{value ? toYYYYMMDD(value) : "-"}</span>,
            flex: 1,
        },
    ];

    const handleInitFilter = () => {
        setRequest({
            ...request,
            keyword: "",
            category: 0,
            classification: 0,
            page: 1,
        });
    };

    const handleSearch = (page = 1) => {
        if (page >= 1) {
            setRequest({ ...request, page });
        }
        getWorkerList({ page });
    };

    const handleDelete = () => {
        if (selectionRows.length <= 0) {
            return alertModal({ message: "At least one data must be selected." });
        }

        setLoading(true);
        WorkService.DeleteWork({ ids: selectionRows })
            .then((response) => {
                alertModal({
                    message: "Delete Success",
                    callback: () => {
                        setLoading(false);
                        handleSearch();
                    },
                });
            })
            .catch((e) => {
                alertModal({ message: e.response.data.message, callback: () => setLoading(false) });
            });
    };

    const handleChangeOrderClose = (refresh?: boolean) => {
        setOpenChangeOrder({ open: false });
        if (refresh) getWorkerList();
    };

    const renderGridButton = () => {
        return (
            <div className="button-area">
                <MenuButton
                    items={[
                        categoryCodes
                            .filter((_, index) => index > 0)
                            .map((item) => ({ label: item.label, category: item.id })),
                        classificationCodes
                            .filter((_, index) => index > 0)
                            .map((item) => ({ label: item.label, classification: item.id })),
                    ]}
                    onClick={({ category, classification }) => {
                        setOpenChangeOrder({ open: true, category, classification });
                    }}
                >
                    Change Order
                </MenuButton>
                <Button color="white" width="100px" onClick={() => history.push(RouterString.AdminRegistWorks)}>
                    Create
                </Button>
                <Button color="white" width="100px" onClick={handleDelete}>
                    Delete
                </Button>
            </div>
        );
    };

    return (
        <Loading spinning={loading}>
            <div className="admin-works-wrapper">
                <PageTitle title="Works" />
                <SearchFilter filterArr={filterArr} onInitFilter={handleInitFilter} onSearch={handleSearch} />
                <div className="data-grid-area">
                    <DataGrid
                        data={list}
                        columns={columns}
                        limit={20}
                        total={total}
                        isCheckBox
                        onSelectionRows={setSelectionRows}
                        renderButtonArea={renderGridButton}
                    ></DataGrid>
                    {list.length < total && (
                        <PaginationWrap
                            currentPage={request.page}
                            totalCount={total}
                            limit={20}
                            onPageChange={(page) => handleSearch(page)}
                        />
                    )}
                </div>
            </div>
            <ChangeOrderPopup {...openChangeOrder} onClose={handleChangeOrderClose} />
        </Loading>
    );
};

export default withSideMenu(AdminWorksManagement, routes);
