import { ISelectBox } from "../API/Interfaces/Common.interface";

//링크에 해당하는 이미지 반환
export const GetStyledCompImg = (src: any) => {
  try {
    const img = require(`Assets/images${src}`);
    return img;
  } catch (e) {
    return "이미지 에러";
  }
};

export const toYYYYMMDD = (value: string) => {
  const date = new Date(value);
  var year = date.getFullYear();
  var month = ("0" + (1 + date.getMonth())).slice(-2);
  var day = ("0" + date.getDate()).slice(-2);

  return year + "-" + month + "-" + day;
};

export const toYYYYMMDDHHMM = (value: string) => {
  const date = new Date(value);
  const yyyymmdd = toYYYYMMDD(value);
  const hh = ("0" + date.getHours()).slice(-2);
  const mm = ("0" + date.getMinutes()).slice(-2);
  return `${yyyymmdd}(${hh}:${mm})`;
};

export const objectToArray = (object: any): any[] => {
  const keys = Object.keys(object);
  const returnVal = keys.map((key) => {
    return object[key];
  });

  return returnVal;
};

export const getClassificationName = (
  classificationCodes: any[],
  id: number
) => {
  const classification: ISelectBox = classificationCodes.find(
    (classification: ISelectBox) => classification.id === id
  );

  return classification?.code;
};
