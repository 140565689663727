import { RouterString } from "../../../src/Component/Modules/routesString";
import Main from "../Main/Main";
import PageNotFound from "../PageNotFound/PageNotFound";
import About from "../About/About";
import Contact from "../Contact/Contact";
import Works from "../Works/HOC/withWorks";
import Login from "Pages/Admin/Login";
import AdminWorksManagement from "Pages/Admin/Works";
import PutWorks from "Pages/Admin/Works/PutWorks";
import AdminAccountsManagement from "Pages/Admin/Accounts";
import PutAccounts from "Pages/Admin/Accounts/PutAccounts";
import AllListItem from "Pages/Works/AllPage/AllListItem";
import GraphicDesign from "Pages/Works/GraphicDesign/GraphicDesign";
import ConceptArt from "Pages/Works/ConceptArt/ConceptArt";
import MusicVideo from "Pages/Works/MusicVideo/MusicVideo";
import Advertising from "Pages/Works/Advertising/Advertising";
import FineArt from "Pages/Works/FineArt/FineArt";

interface PageProps {
  path: string;
  component: any;
  exact?: boolean;
}
const pages: PageProps[] = [
  {
    path: RouterString.PageNotFound,
    component: PageNotFound,
    exact: true,
  },
  // Home
  {
    path: RouterString.Main,
    component: Main,
    exact: false,
  },
  {
    path: RouterString.About,
    component: About,
    exact: true,
  },
  {
    path: RouterString.Contact,
    component: Contact,
    exact: true,
  },
  {
    path: RouterString.Works,
    component: Works,
    exact: true,
  },
  {
    path: RouterString.WorkAll,
    component: AllListItem,
    exact: true,
  },
  {
    path: RouterString.WorkGraphicDesign,
    component: GraphicDesign,
    exact: true,
  },
  {
    path: RouterString.WorkFineArt,
    component: FineArt,
    exact: true,
  },
  {
    path: RouterString.WorkConceptArt,
    component: ConceptArt,
    exact: true,
  },
  {
    path: RouterString.WorkMV,
    component: MusicVideo,
    exact: true,
  },
  {
    path: RouterString.WorkAdvertising,
    component: Advertising,
    exact: true,
  },
  // Admin
  {
    path: RouterString.AdminLogin,
    component: Login,
    exact: true,
  },
  {
    path: RouterString.AdminWorks,
    component: AdminWorksManagement,
    exact: true,
  },
  {
    path: RouterString.AdminRegistWorks,
    component: () => <PutWorks isRegist />,
    exact: true,
  },
  {
    path: RouterString.AdminUpdateWorks,
    component: () => <PutWorks />,
    exact: true,
  },
  {
    path: RouterString.AdminAccounts,
    component: AdminAccountsManagement,
    exact: true,
  },
  {
    path: RouterString.AdminRegistAccounts,
    component: () => <PutAccounts isRegist />,
    exact: true,
  },
  {
    path: RouterString.AdminUpdateAccounts,
    component: () => <PutAccounts />,
    exact: true,
  },
];

export default pages;
