import React, { useEffect, useState } from "react";

import styled from "styled-components/macro";
import { ReactComponent as PrevArrowIcon } from "Assets/images/Admin/Icons/ico-arrow-prev.svg";
import { ReactComponent as PrevDoubleArrowIcon } from "Assets/images/Admin/Icons/ico-double-arrow-prev.svg";
import { ReactComponent as NextArrowIcon } from "Assets/images/Admin/Icons/ico-arrow-next.svg";
import { ReactComponent as NextDoubleArrowIcon } from "Assets/images/Admin/Icons/ico-double-arrow-next.svg";
import Pagination from "react-js-pagination";

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  gap: 4px;
  margin-top: 16px;
  height: 26px;
  .pagination {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: unset;
    a {
      display: flex;
      text-decoration: none;
      /* text-align: center; */
      align-items: center;
      justify-content: center;
      width: 26px;
      height: 26px;
      font-size: var(--font-size7);

      color: #070707;
      cursor: pointer;
      &.active,
      &:hover {
        color: #ffffff;
        background-color: var(--color-main);
      }
    }

    .arrow-btn {
      &.prev {
        margin-right: 15px;
      }
      &.next {
        margin-left: 15px;
      }
      a:hover {
        background-color: unset;
        color: unset;
      }
    }
  }
`;
const Item = styled.div`
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;

  svg {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`;

interface IPaginationProps {
  currentPage: number;
  totalCount: number;
  limit: number;
  onPageChange: (page: number) => void;
  pageGroupSize?: number;
  wrapperClassName?: string;
}
const PaginationWrap: React.FC<IPaginationProps> = ({
  currentPage = 1,
  totalCount,
  limit,
  onPageChange,
  pageGroupSize = 10,
  wrapperClassName,
}) => {
  const handlePageChange = (pageNumber: number) => {
    // setActivePage(pageNumber);

    if (pageNumber === currentPage) {
      return;
    }
    if (pageNumber <= maxPage && pageNumber > 0) {
      if (onPageChange) {
        onPageChange(pageNumber);
      }
    }
  };
  const maxPage =
    Math.floor(totalCount / limit) + (totalCount % limit > 0 ? 1 : 0);

  return totalCount > 0 ? (
    <PaginationWrapper className={wrapperClassName}>
      <Pagination
        activePage={currentPage}
        itemsCountPerPage={limit}
        totalItemsCount={totalCount}
        pageRangeDisplayed={pageGroupSize}
        onChange={handlePageChange}
        activeLinkClass="active"
        itemClassPrev="arrow-btn prev"
        itemClassFirst="arrow-btn"
        itemClassNext="arrow-btn next"
        itemClassLast="arrow-btn"
        firstPageText={
          <Item onClick={() => handlePageChange(1)}>
            {/* 첫번째 페이지 */}
            <PrevDoubleArrowIcon aria-hidden="true"></PrevDoubleArrowIcon>
          </Item>
        }
        prevPageText={
          <Item onClick={() => handlePageChange(currentPage - 1)}>
            {/* 이전 페이지 */}
            <PrevArrowIcon aria-hidden="true"></PrevArrowIcon>
          </Item>
        }
        lastPageText={
          <Item onClick={() => handlePageChange(maxPage)}>
            {/* 마지막 페이지 */}
            <NextDoubleArrowIcon></NextDoubleArrowIcon>
          </Item>
        }
        nextPageText={
          <Item onClick={() => handlePageChange(currentPage + 1)}>
            {/* 다음 페이지 */}
            <NextArrowIcon aria-hidden="true"></NextArrowIcon>
          </Item>
        }
      />
    </PaginationWrapper>
  ) : (
    <></>
  );
};

export default PaginationWrap;
