import {
  IGetDetailWork,
  IReqCreateWork,
  IReqUpdateWork,
} from "../API/Interfaces/Work.interface";

export type TValidInfo = {
  isValid: boolean;
  message: string;
};

const SendFail: (m: string) => TValidInfo = (message: string) => {
  return { isValid: false, message };
};
const Success: () => TValidInfo = () => ({ isValid: true, message: "" });

const CommonCheck = (data: IReqCreateWork | IReqUpdateWork) => {
  if (!data.titleKor || data.titleKor.trim() === "") {
    return SendFail("The Title KR is empty");
  }
  if (!data.titleEng || data.titleEng.trim() === "") {
    return SendFail("The Title EN is empty");
  }
  if (!data.shortTitleKor || data.shortTitleKor.trim() === "") {
    return SendFail("The Side Title KR is empty");
  }
  if (!data.shortTitleEng || data.shortTitleEng.trim() === "") {
    return SendFail("The Side Title EN is empty");
  }
  if (data.categoryId <= 0) {
    return SendFail("The Category is not selected");
  }
  if (data.classificationId <= 0) {
    return SendFail("The Classification is not selected");
  }
  const updateCheck = data as IReqUpdateWork;
  if (!updateCheck.id && !data.thumbnailFile) {
    return SendFail("The Thumbnail File is empty");
  }
  if (!updateCheck.id && !data.subThumbnailFile) {
    return SendFail("The SubThumbnail File is empty");
  }
  if (data.year && data.year.length > 4) {
    return SendFail("The Year is too long");
  }
  return Success();
};

export const ValidCreateWork = (data: IReqCreateWork) => {
  const common = CommonCheck(data);
  if (!common.isValid) {
    return common;
  }
  if (
    (!data.imageFileArr ||
      data.imageFileArr.length === 0 ||
      data.imageFileArr[0] === null) &&
    (!data.videoUrl || data.videoUrl?.trim() === "")
  ) {
    return SendFail(`The Image File or Video Url is empty`);
  }
  if (
    data.imageFileArr &&
    data.imageFileArr.length > 0 &&
    data.imageFileArr[0] !== null
  ) {
    for (let loop = 0; loop < data.imageFileArr.length; loop++) {
      if (!data.imageFileArr[loop]) {
        return SendFail(`The Image File is not valid.[No.${loop + 1}]`);
      }
    }
  }
  return Success();
};

export const ValidUpdateWork = (
  data: IReqUpdateWork,
  detail: IGetDetailWork
) => {
  const common = CommonCheck(data);
  if (!common.isValid) {
    return common;
  }
  if (!detail.subThumbnailUrl || detail.subThumbnailUrl === "") {
    return SendFail("The SubThumbnail File is empty");
  }
  if (!detail.thumbnailUrl || detail.thumbnailUrl === "") {
    return SendFail("The Thumbnail File is empty");
  }
  if (
    (!data.imageFileArr ||
      data.imageFileArr.length === 0 ||
      data.imageFileArr[0] === null) &&
    (!detail.imageArr ||
      detail.imageArr.length === 0 ||
      detail.imageArr[0].img_url === "") &&
    (!data.videoUrl || data.videoUrl?.trim() === "")
  ) {
    return SendFail(`The Image File or Video Url is empty`);
  }

  return Success();
};
