import { FormControlLabel, RadioGroup } from "@mui/material";
import React from "react";
import Radio from ".";
import "./Radio.scss";

interface IRadioArrayProps {
	items: string[];
	value: string;
	readonly?: boolean;
	onChange: (value: string) => void;
}
const RadioArray: React.FC<IRadioArrayProps> = ({ items, value, readonly, onChange }) => {
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (onChange) {
			onChange(e.target.value);
		}
	};
	return (
		<RadioGroup className="radio-group" row defaultValue={"0"} onChange={e => handleChange(e)}>
			{items.map((item, index) => {
				return (
					<FormControlLabel
						key={`${item}_${index}`}
						className="radio-form"
						value={index}
						control={<Radio />}
						label={item}
						checked={String(index) === value}
						disabled={readonly}
					/>
				);
			})}
		</RadioGroup>
	);
};

export default RadioArray;
