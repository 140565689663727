import { RouterString } from "Component/Modules/routesString";
import { IRouteMenu } from "Component/Modules/Utils/Config";

const routes: IRouteMenu[] = [
  {
    path: RouterString.AdminWorks,
    title: "WorksMng",
    deactiveImg: "/Admin/Icons/ico-worker.svg",
    activeImg: "/Admin/Icons/ico-worker.svg",
    isMainTab: true,
  },
  {
    path: RouterString.AdminWorks,
    title: "Works",
    isMainTab: false,
  },
];

export default routes;
