import UploadFile from "Component/Admin/UploadFile";
import Button from "Component/Admin/Button";
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import { getItemStyle } from "Utils/DndFunction";

interface UploadCardProps {
    fileName?: string;
    src: string;
    keyId: string;
    listLength: number;
    provided: DraggableProvided;
    snapshot: DraggableStateSnapshot;
    onChange: (file: File, url: string) => void;
    onDelete?: () => void;
    onClickPlus: () => void;
    onClickMinus: () => void;
}
export default function UploadCard({
    fileName,
    src,
    keyId,
    listLength,
    provided,
    snapshot,
    onChange,
    onDelete,
    onClickPlus,
    onClickMinus,
}: UploadCardProps) {
    const renderButtons = (isHidePlus?: boolean, isHideMinus?: boolean) => {
        return (
            <div className="btn-cols">
                {!isHidePlus && (
                    <Button width="30px" color="white" onClick={onClickPlus}>
                        {"+"}
                    </Button>
                )}
                {!isHideMinus && (
                    <Button width="30px" color="white" onClick={onClickMinus}>
                        {"-"}
                    </Button>
                )}
            </div>
        );
    };
    return (
        <div
            ref={provided.innerRef}
            className="item-value-col"
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
            <UploadFile
                format={[".png", ".jpeg", ".jpg"]}
                fileName={fileName}
                src={src}
                onChange={onChange}
                onDelete={onDelete}
                keyId={keyId}
            />
            {renderButtons(false, listLength <= 1)}
        </div>
    );
}
