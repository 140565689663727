import React from "react";
import Radio, { RadioProps } from "@mui/material/Radio";
import { ReactComponent as RadioBtnOn } from "Assets/images/Admin/Icons/ico-radio-on.svg";
import { ReactComponent as RadioBtnOff } from "Assets/images/Admin/Icons/ico-radio-off.svg";
import styled from "styled-components";
import "./Radio.scss";

const RadioOn = styled(RadioBtnOn)`
	color: var(--color-main); //#FF671D
`;

const RadioWrapper: React.FC<RadioProps> = props => {
	return <Radio icon={<RadioBtnOff />} checkedIcon={<RadioOn />} {...props} />;
};

export default RadioWrapper;
