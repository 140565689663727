import styled from "@emotion/styled";
import { Button, Divider, Menu, MenuItem } from "@mui/material";
import { PropsWithChildren, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface MenuButtonProps extends PropsWithChildren<any> {
    items: { category?: number; classification?: number; label: string }[][];
    onClick: (params: { category?: number; classification?: number }) => void;
}
export default function MenuButton({ items, onClick, children }: MenuButtonProps) {
    const [anchorEl, setAnchorEl] = useState<boolean>(false);
    const open = Boolean(anchorEl);
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(false);
    };

    return (
        <div>
            <Button
                variant="outlined"
                color="inherit"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                sx={{ borderRadius: 0 }}
            >
                {children}
            </Button>
            <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {items.map((group, index) => {
                    return (
                        <div key={`group-${index}`}>
                            {group.map((item, index) => {
                                const { category, classification } = item;
                                return (
                                    <MenuItem
                                        key={index}
                                        onClick={() => {
                                            onClick({ category, classification });
                                            setAnchorEl(false);
                                        }}
                                    >
                                        {item.label}
                                    </MenuItem>
                                );
                            })}
                            {index < items.length && <Divider sx={{ my: 0.5 }} />}
                        </div>
                    );
                })}
            </StyledMenu>
        </div>
    );
}

const StyledMenu = styled((props: any) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({}));
