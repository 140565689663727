import React from "react";
import "./Button.scss";

interface IButtonProps {
  color?: "default" | "white";
  width?: string;
  height?: string;
  value?: string; // lang???
  onClick?: (e: any) => void;
  children?: any;
  style?: any;
  disabled?: boolean;
}
const Button: React.FC<IButtonProps> = ({
  color = "default",
  width = "100%",
  height = "32px",
  style,
  children,
  value,
  onClick,
  disabled,
}) => {
  return (
    <div
      className={`button ${disabled ? "disabled" : color}`}
      style={{
        ...style,
        width: width,
        height: height,
        display: "table",
        textAlign: "center",
      }}
      onClick={disabled ? () => {} : onClick}
    >
      <div
        lang={value}
        style={{ display: "table-cell", verticalAlign: "middle" }}
      >
        {children}
      </div>
    </div>
  );
};

export default Button;
