import { fontSize } from "@mui/system";
import InputField from "Component/Admin/Input/InputField";
import MultilineInputField from "Component/Admin/Input/MultilineInputField";
import "Pages/Admin/Common.style.scss";

interface IRowTextField {
    keyId: string;
    title: string;

    text: string;
    placeholder?: string;
    onChange: (keyId: string, value: string) => void;
}
const RowMultiLineField: React.FC<IRowTextField> = ({ keyId, title, text, placeholder, onChange }) => {
    const handleChange = (text: string) => {
        if (onChange) {
            onChange(keyId, text);
        }
    };
    return (
        <div className="row" style={{ height: "100px" }}>
            <div className="item-key">{title}</div>
            <div className="item-value" style={{ height: "100%", paddingTop: "5px", paddingBottom: "5px" }}>
                <MultilineInputField text={text} placeholder={placeholder} onChange={handleChange} />
            </div>
        </div>
    );
};

export default RowMultiLineField;
