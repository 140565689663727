import { atom, DefaultValue, selector } from "recoil";
import { recoilPersist } from "recoil-persist";

enum eModalType {
    Alert = "alert",
    Confirm = "confirm"
}

interface IModalOpt {
    key?: eModalType.Alert | eModalType.Confirm;
    id?: string;
    message: string;
    callback?: ()=> void;
    okLabel?: string;
    okFn?: () => void;
    closeLabel?: string;
}

const Modals = atom<IModalOpt[]>({
    key: "modal-list",
    default: [
        // {key: "alert", message: "", callback: () => {}}
    ],
});

const AlertModal = selector<IModalOpt>({
    key: "alert-modal",
    get: () => {
        return { message: "" };
    },
    set: ({set, get}, newValue) => {
        const modals = get(Modals);
        let val = newValue as IModalOpt;
        val.key = eModalType.Alert;
        val.id = val.key+"_"+modals.length;
        set(Modals, [...modals, val]);
    }
})

const ConfirmModal = selector<IModalOpt>({
    key: "confirm-modal",
    get: () => {
        return { message: "" };
    },
    set: ({set, get}, newValue) => {
        const modals = get(Modals);
        let val = newValue as IModalOpt;
        val.key = eModalType.Confirm;
        val.id = val.key+"_"+modals.length;
        set(Modals, [...modals, val]);
    }
})

const DeleteModal = selector<IModalOpt>({
    key: "delete-modal",
    get: () => {
        return { message: "" };
    },
    set: ({set, get}, newValue) => {
        const modals = get(Modals);
        const val: any = newValue;
        const filtered = modals.filter((item: any)=> item.id !== val.id);
        set(Modals, [...filtered])
    }
})

export { eModalType, Modals, AlertModal, ConfirmModal, DeleteModal }