import React, { useEffect, useState } from "react";
import "./style.scss";
import AllItemsDialog from "../../../Component/ContentDialog/ContentDialog";
import { scrollTop } from "Utils/ScrollTop";
import { Link, useLocation } from "react-router-dom";
import CommonService from "Component/Modules/API/Services/Common.service";
import { PopupProps } from "../types/type";
import Skeleton from "Component/Skeleton/Skeleton";
import useDidMountEffect from "Component/UseDisMountEffect/Function";
import withWorks from "../HOC/withWorks";

function Advertising() {
  const [workList, setWorkList] = useState<PopupProps[]>([]);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    CommonService.GetWorkAll([5])
      .then((response:any) => {
        if (response) {
          const data = response.filter((item: any) => item.sub_category === 'Advertising')
          setWorkList(data);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);
  function SkeletonContentsList(size: number) {
    var list = [];
    for (var i = 0; i < size; i++) {
      list.push(
        <div key={i}>
          <Skeleton type="threeElements" height={340} variant="rounded"></Skeleton>
        </div>
      );
    }
    return list;
  }
  useDidMountEffect(() => {
    setLoading(false);
  }, [workList]);
  useEffect(() => {
    return () => setLoading(false);
  }, []);
  return (
    <>
      {workList.map((item, index) => (
        <React.Fragment key={index}>
          {location.hash === "#" + item.id && (
            <>
              <AllItemsDialog data={item} />
              <p className="titleCategory">Abvertising</p>
            </>
          )}
        </React.Fragment>
      ))}
      <div className={`advertising`} style={{ width: "100%" }}>
        {loading === false
          ? workList.map((item, index) => (
              <React.Fragment key={index}>
                <Link
                  to={{ search: "advertising", hash: String(item.id) }}
                  onClick={() => {
                    scrollTop();
                  }}
                >
                  <div className="works-item">
                    <img src={item.sub_thumbnail} alt="" />
                    <div className="card-info">
                      <div className="card-title">
                        <span style={{ textTransform: "uppercase" }}>
                          Advertising
                        </span>
                      </div>
                      <div
                        className="card-content"
                        style={{ textAlign: "center" }}
                      >
                        <p>{item.name.en}</p>
                        <p>{item.year}</p>
                      </div>
                    </div>
                  </div>
                </Link>
              </React.Fragment>
            ))
          : SkeletonContentsList(18)}
      </div>
    </>
  );
}

export default withWorks(Advertising);
