import React, { useState } from 'react';
import { Button } from 'antd';
import './ButtonCustom.scss';

interface ButtonDefaultProps {
  children: any;
  style?: React.CSSProperties;
  className?: string;
}

function ButtonDefault(props: ButtonDefaultProps) {
  const { children, style, className } = props;
  const [isActive, setIsActive] = useState(false);

  const toggleActive = () => setIsActive(!isActive);

  return (
    <button
      style={style}
      onClick={toggleActive}
      className={`${
        isActive
          ? `default-button active ${className ? className : ''}`
          : `default-button ${className ? className : ''}`
      }`}
    >
      {children}
    </button>
  );
}

export default ButtonDefault;
