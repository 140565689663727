import { AxiosRequestConfig } from "axios";
import { get, IResponse } from "../AxiosUtil";
import { IResWorkContent, ISelectBox } from "../Interfaces/Common.interface";
import WorksService from '../../APIContentful/work.service'
// 시스템에서 즉시 호출해야하는 API가 있어서 AxiosUtil에서 VER 변수를 호출할 수 없음. (/api/v1 하드코딩)
const GET_CATEGORY_LIST = "/api/v1/common/category";
const GET_CLASSIFICATION_LIST = "/api/v1/common/classification";
const GET_WORK_ALL = "/api/v1/common/works";

class CommonService {
  GetCategoryList = async (
    config?: AxiosRequestConfig
  ): Promise<ISelectBox[] | null> => {
    try {
      const response = await WorksService.getSubCategorys();
      if (response) {
        const converted = response.items.map((item: any) => ({
          id: item.sys.id,
          label: item.fields.name,
          code: item.fields.name
        }));
        return [{ id: 0, label: "ALL" } as ISelectBox].concat(converted);
      }
      return null;
    } catch (e) {
      throw e;
    }
  };

  GetClassificationList = async (
    config?: AxiosRequestConfig
  ): Promise<ISelectBox[] | null> => {
    try {
      const response = await WorksService.getCategorys();
      if (response) {
        const converted = response.items.map((item: any) => ({
          id: item.sys.id,
          label: item.fields.name,
          code: item.fields.name
        }));
        return [{ id: 0, label: "ALL" } as ISelectBox].concat(converted);
      }
      return null;
    } catch (e) {
      throw e;
    }
  };

  GetWorkAll = async (categoryIds?: number[], config?: AxiosRequestConfig
    ): Promise<IResWorkContent | null> => {
      try {
        const response = await WorksService.getWorks();
        if (response) {
          return response;
        }
        return null;
      } catch (e) {
        throw e;
      }
    };
}

export default new CommonService();
