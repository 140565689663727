import { CircularProgress, Dialog } from "@material-ui/core";
import Button from "Component/Admin/Button";
import { useEffect, useState } from "react";
import OrderCard from "./OrderCard";
import WorkService from "Component/Modules/API/Services/Work.service";
import { ReactComponent as IconClose } from "Assets/images/Admin/Icons/ico-close.svg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./style.scss";
import { reorder } from "Utils/DndFunction";

/**
 * 모든 works
 */

interface ChangeOrderPopupProps {
    open: boolean;
    category?: number;
    classification?: number;
    onClose: (refresh?: boolean) => void;
}
export default function ChangeOrderPopup({ open, category, classification, onClose }: ChangeOrderPopupProps) {
    const [list, setList] = useState<any>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (open) {
            // 서버에서 타겟이 되는 리스트 조회 (categoryId or classificationId)
            setLoading(true);
            WorkService.GetWorkListForOrder({ category, classification }).then((response) => {
                setList(response.data.map((item: any, index: number) => ({ ...item, index: index + 1 })));
                setLoading(false);
            });
        }
    }, [open]);

    const handleConfirm = () => {
        WorkService.UpdateWorksOrder({ work_ids: list.map((item: any) => item.id), order_type: category ? 1 : 2 }).then(
            (response) => {
                onClose(true);
            }
        );
    };

    

    const onDragEnd = (result: any) => {
        // dropped outside the list(리스트 밖으로 드랍한 경우)
        if (!result.destination) {
            return;
        }

        const items = reorder(list, result.source.index, result.destination.index);

        setList(items);
    };

    return (
        <Dialog open={open} onClose={() => onClose()} className="change-order-popup" disableEnforceFocus>
            <div className="title-area">
                <span>Change Order</span>
                <IconClose onClick={() => onClose()} />
            </div>
            <div className="content-area">
                {loading ? (
                    <div className="loading">
                        <CircularProgress />
                    </div>
                ) : (
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {list?.map((item: any, index: number) => (
                                        <Draggable key={item.id} draggableId={String(item.id)} index={index}>
                                            {(provided, snapshot) => (
                                                <OrderCard item={item} provided={provided} snapshot={snapshot} />
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                )}

                <div className="button-area">
                    <Button width="100px" onClick={handleConfirm}>
                        Confirm
                    </Button>
                    <Button width="100px" color="white" onClick={onClose}>
                        Cancel
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}
