import React from "react";
import { Spin } from "antd";

interface ILoadingProps {
    className?: string;
    spinning: boolean;
    tip?: string;
}
const Loading: React.FC<ILoadingProps> = (props) => {
    return <div className={props.className ? props.className : undefined}>
        <Spin 
            spinning={props.spinning} 
            tip={props.tip}
            style={{ height: '50%' }} 
        >
            {props.children}
        </Spin>
    </div>
}

export default Loading;