import React from "react";
import { ReactComponent as Icon1 } from "Assets/images/Admin/Icons/ico-user.svg";
import { ReactComponent as Icon2 } from "Assets/images/Admin/Icons/ico-lock.svg";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import "./InputField.scss";
import styled from "styled-components";

const IcoUser = styled(Icon1)`
    color: var(--color-main);
`;
const IcoLock = styled(Icon2)`
    color: var(--color-main);
`;
interface ITextFieldProps {
    placeholder?: string;
    text: string;
    type?: "text" | "id" | "password";
    width?: string;
    height?: string;
    onChange?: (text: string) => void;
    readOnly?: boolean;
}
const MultilineInputField: React.FC<ITextFieldProps> = ({ placeholder, text, type = "text", onChange, width = "340px", height = "100%", readOnly }) => {
    const handleChange = (e: any) => {
        if (onChange) {
            onChange(e.target.value);
        }
    };
    return (
        <TextField
            InputProps={
                type !== "text"
                    ? {
                          startAdornment: (
                              <InputAdornment position="start">
                                  {type === "id" && <IcoUser />}
                                  {type === "password" && <IcoLock />}
                              </InputAdornment>
                          ),
                      }
                    : {
                          readOnly: readOnly,
                      }
            }
            multiline
            maxRows={5}
            placeholder={placeholder}
            value={text}
            onChange={handleChange}
            style={{
                width,
                height,
            }}
            variant="outlined"
            type={type}
        />
    );
};

export default MultilineInputField;
