import Button from "Component/Admin/Button";
import TextField from "Component/Admin/Input/InputField";
import {
  AuthenticatedState,
  UserAccountState,
} from "Component/Modules/Store/Common.recoil";
import { setUserToken } from "Component/Modules/Store/Cookie";
import { AlertModal } from "Component/Modules/Store/Modal.recoil";
import { RouterString } from "Component/Modules/routesString";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import "./style.scss";
import AuthService from "Component/Modules/API/Services/Auth.service";

function Login() {
  const history = useHistory();
  const [request, setRequest] = useState({
    username: "",
    password: "",
  });
  const setAuthenticatedState = useSetRecoilState(AuthenticatedState);
  const setUserAccount = useSetRecoilState(UserAccountState);
  const alertModal = useSetRecoilState(AlertModal);

  const handleInputChange = (type: "username" | "password", value: string) => {
    setRequest({ ...request, [type]: value });
  };

  const handleLogin = async () => {
    try {
      const response = await AuthService.Login(request);
      if (response && response.code === "OK200") {
        const { name, accessToken } = response.data;
        setUserToken(accessToken);
        setAuthenticatedState(true);
        setUserAccount({ name, id: request.username });
        history.push(RouterString.AdminWorks);
      }
    } catch (e: any) {
      alertModal({ message: e.response.data.message });
    }
  };

  return (
    <>
      <div className="login-wrapper">
        <div className="logo">XUXU 통합 관리자</div>
        <div className="login-box">
          <TextField
            type="id"
            placeholder="아이디 입력"
            text={request.username}
            onChange={(text) => handleInputChange("username", text)}
          />
          <TextField
            type="password"
            placeholder="비밀번호 입력"
            text={request.password}
            onChange={(text) => handleInputChange("password", text)}
          />
          <Button width="100%" onClick={handleLogin}>
            로그인
          </Button>
        </div>
      </div>
    </>
  );
}

export default Login;
