import React, { useEffect, useState } from "react";
import "./AllListItem.scss";
import AllItemsDialog from "../../../Component/ContentDialog/ContentDialog";
import { PopupProps } from "../types/type";
import { scrollTop } from "Utils/ScrollTop";
import { Link, useLocation } from "react-router-dom";
import CommonService from "Component/Modules/API/Services/Common.service";
import { useRecoilState, useRecoilValue } from "recoil";
import { ClassificationCode, LangCode } from "Component/Modules/Store/Code.recoil";
import { getClassificationName } from "Component/Modules/Utils/Function";
import Skeleton from "Component/Skeleton/Skeleton";
import useDidMountEffect from "Component/UseDisMountEffect/Function";
import WithWorks from "../HOC/withWorks";

function AllListItem() {
    const [workList, setWorkList] = useState<PopupProps[]>([]);
    const location = useLocation();
    const classificationCodes = useRecoilValue(ClassificationCode);
    const [loading, setLoading] = useState(true);
    const [langCode, setLangCode] = useRecoilState(LangCode);
    const [selectedAllListItem, setSelectedAllListItem] = useState<PopupProps | null>(null);

    useEffect(() => {
        CommonService.GetWorkAll()
            .then((response: any) => {
                console.log(response);
                if (response) {
                    setWorkList(response);
                }
            })
            .catch((e) => {
                console.error(e);
            });
    }, []);

    const parseClassificationName = (id: number) => {
        const name = getClassificationName(classificationCodes, id);
        return name;
    };
    function SkeletonList(size: number) {
        var list = [];
        for (var i = 0; i < size; i++) {
            list.push(<Skeleton variant="text" width={'80%'} height={30} />);
        }
        return list;
    }
    function SkeletonContentsList(size: number) {
        var list = [];
        for (var i = 0; i < size; i++) {
            list.push(
                <div key={i} className="element">
                    <Skeleton  variant="rounded" height={280} />
                     <Skeleton  variant="text"  height={50} />
                </div>
            );
        }
        return list;
    }

    useDidMountEffect(() => {
        setLoading(false);
    }, [workList]);
    useEffect(() => {
        return () => setLoading(false);
    }, []);
    return (
        <>
            <div className="allItem">
                <div className="allItem-menu">
                    <div className="menu">
                        <div className="menu-bigTitle">
                            CLIENT WORK <span></span>
                        </div>
                        <div className="side-sub-title">
                            {loading === false
                                ? workList.map((el: PopupProps, index: number) => (
                                      <React.Fragment key={index}>
                                          {el.category === "CLIENT WORK" && (
                                              <Link to={{ search: "all", hash: String(el.id) }}>
                                                  <div
                                                      className={`menu-subTitle ${selectedAllListItem?.thumbnail === el.thumbnail ? "subTitleActive" : ""}`}
                                                      key={index}
                                                      onClick={() => {
                                                          scrollTop();
                                                          setSelectedAllListItem(el);
                                                      }}
                                                  >
                                                      {langCode === "en" && el.short_name.en}
                                                      {langCode === "kr" && el.short_name.ko}
                                                  </div>
                                              </Link>
                                          )}
                                      </React.Fragment>
                                  ))
                                : SkeletonList(40)}
                        </div>
                        <div className="menu-bigTitle">
                            GRAPHIC DESIGN <span></span>
                        </div>
                        <div className="side-sub-title">
                            {loading === false
                                ? workList.map((el: PopupProps, index) => {
                                      return (
                                          <React.Fragment key={index}>
                                              {el.category === "GRAPHIC DESIGN" && (
                                                  <Link to={{ search: "all", hash: String(el.id) }}>
                                                      <div
                                                          className={`menu-subTitle ${selectedAllListItem?.thumbnail === el.thumbnail ? "subTitleActive" : ""}`}
                                                          key={index}
                                                          onClick={() => {
                                                              scrollTop();
                                                              setSelectedAllListItem(el);
                                                          }}
                                                      >
                                                          {langCode === "en" && el.short_name.en}
                                                          {langCode === "kr" && el.short_name.ko}
                                                      </div>
                                                  </Link>
                                              )}
                                          </React.Fragment>
                                      );
                                  })
                                : SkeletonList(10)}
                        </div>
                        <div className="menu-bigTitle">
                            ORIGINAL WORK <span></span>
                        </div>
                        <div className="side-sub-title">
                            {loading === false
                                ? workList.map((el: PopupProps, index) => (
                                      <React.Fragment key={index}>
                                          {el.category === "ORIGINAL WORK" && (
                                              <Link to={{ search: "all", hash: String(el.id) }}>
                                                  <div
                                                      className={`menu-subTitle ${selectedAllListItem?.thumbnail === el.thumbnail ? "subTitleActive" : ""}`}
                                                      key={index}
                                                      onClick={() => {
                                                          scrollTop();
                                                          setSelectedAllListItem(el);
                                                      }}
                                                  >
                                                      {langCode === "en" && el.short_name.en}
                                                      {langCode === "kr" && el.short_name.ko}
                                                  </div>
                                              </Link>
                                          )}
                                      </React.Fragment>
                                  ))
                                : SkeletonList(10)}
                        </div>
                    </div>
                </div>
                <div className="allItem-list">
                    {workList.map((item, index) => (
                        <React.Fragment key={index}>
                            {location.hash === "#" + item.id && (
                                <>
                                    <AllItemsDialog data={item} />
                                </>
                            )}
                        </React.Fragment>
                    ))}
                    <div className="list">
                        <>
                            <div className="list-bigTitle">
                                CLIENT WORK <span></span>
                            </div>
                            <div className="list-element">
                                {loading === false
                                    ? workList.map((item, index) => {
                                          return (
                                              <React.Fragment key={index}>
                                                  {item.category === "CLIENT WORK" && (
                                                      <div className="element">
                                                          <Link to={{ search: "all", hash: String(item.id) }}>
                                                              <div
                                                                  onClick={() => {
                                                                      scrollTop();
                                                                      setSelectedAllListItem(item);
                                                                  }}
                                                                  className="img-box"
                                                              >
                                                                  <img alt="" src={item.thumbnail} />
                                                              </div>
                                                          </Link>
                                                          <p className="element-title">
                                                              {langCode === "en" && item.name.en}
                                                              {langCode === "kr" && item.name.ko}
                                                          </p>
                                                      </div>
                                                  )}
                                              </React.Fragment>
                                          );
                                      })
                                    : SkeletonContentsList(30)}
                            </div>
                        </>
                        <>
                            <div className="list-bigTitle">
                                GRAPHIC DESIGN <span></span>
                            </div>
                            <div className="list-element">
                                {loading === false
                                    ? workList.map((item, index) => (
                                          <React.Fragment key={index}>
                                              {item.category === "GRAPHIC DESIGN" && (
                                                  <div className="element">
                                                      <Link to={{ search: "all", hash: String(item.id) }}>
                                                          <div
                                                              onClick={() => {
                                                                  scrollTop();
                                                                  setSelectedAllListItem(item);
                                                              }}
                                                              className="img-box"
                                                          >
                                                              <img alt="" src={item.thumbnail} />
                                                          </div>
                                                      </Link>
                                                      <p className="element-title">
                                                          {langCode === "en" && item.name.en}
                                                          {langCode === "kr" && item.name.ko}
                                                      </p>
                                                  </div>
                                              )}
                                          </React.Fragment>
                                      ))
                                    : SkeletonContentsList(20)}
                            </div>
                        </>
                        <>
                            <div className="list-bigTitle">
                                ORIGINAL WORK <span></span>
                            </div>
                            <div className="list-element">
                                {loading === false
                                    ? workList.map((item, index) => (
                                          <React.Fragment key={index}>
                                              {item.category === "ORIGINAL WORK" && (
                                                  <div className="element">
                                                      <Link to={{ search: "all", hash: String(item.id) }}>
                                                          <div
                                                              onClick={() => {
                                                                  scrollTop();
                                                                  setSelectedAllListItem(item);
                                                              }}
                                                              className="img-box"
                                                          >
                                                              <img alt="" src={item.thumbnail} />
                                                          </div>
                                                      </Link>
                                                      <p className="element-title">
                                                          {langCode === "en" && item.name.en}
                                                          {langCode === "kr" && item.name.ko}
                                                      </p>
                                                  </div>
                                              )}
                                          </React.Fragment>
                                      ))
                                    : SkeletonContentsList(20)}
                            </div>
                        </>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WithWorks(AllListItem);
