import { PropsWithChildren, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import TextUnderline from "Component/TextUnderline/TextUnderline";
import ScrollToTop from "Component/ScrollToTop/ScrollToTop";
import "./style.scss";
import { RouterString } from "Component/Modules/routesString";
import { PopupProps } from "../types/type";
import { RouteMenuKey, ROUTER_MENU } from "./WorkHeader.type";

const WorksHeader = ({ children }: PropsWithChildren<any>) => {
    const location = useLocation();

    const [isMenuShow] = useState(false);
    const [active, setActive] = useState("all");

    useEffect(() => {
        switch (location.pathname) {
            case RouterString.WorkAll:
                setActive(ROUTER_MENU.ALL.key);
                break;
            case RouterString.WorkGraphicDesign:
                setActive(ROUTER_MENU.GRAPHIC_DESIGN.key);
                break;
            case RouterString.WorkFineArt:
                setActive(ROUTER_MENU.FINE_ART.key);
                break;
            case RouterString.WorkConceptArt:
                setActive(ROUTER_MENU.CONCEPT_ART.key);
                break;
            case RouterString.WorkMV:
                setActive(ROUTER_MENU.MV_TITLE.key);
                break;
            case RouterString.WorkAdvertising:
                setActive(ROUTER_MENU.ADVERTISING.key);
                break;
        }
    }, [location]);

    return (
        <>
            <ScrollToTop />
            <div className="works">
                <nav className="nav">
                    <ul className={`${isMenuShow ? "nav-bar active" : "nav-bar"}`}>
                        {Object.keys(ROUTER_MENU).map((key, index) => {
                            const menu = ROUTER_MENU[key as RouteMenuKey];
                            return (
                                <li className="nav-item" key={index}>
                                    <Link to={menu.path} className={`nav-link ${active === menu.key ? "active" : ""}`}>
                                        {menu.title}
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </nav>
                <Link to={{ search: location.search }}>
                    <div className="works-desc">
                        <div className="works-title">
                            <TextUnderline>
                                <span style={{ textTransform: "capitalize", cursor: "pointer" }}>{active}</span>
                            </TextUnderline>
                        </div>
                    </div>
                </Link>
                <div>{children}</div>
            </div>
        </>
    );
};

export default WorksHeader;
