import { AlertModal } from "Component/Modules/Store/Modal.recoil";
import React, { useEffect, useRef } from "react";
import { ReactComponent as IconClose } from "Assets/images/Admin/Icons/ico-close.svg";
import { useSetRecoilState } from "recoil";
import Button from "Component/Admin/Button";
import styled from "styled-components";

const UploadWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 15px;
`;
const UploadItem = styled.div`
  display: flex;
  align-items: center;
  column-gap: 6px;
  img {
    width: 28px;
    height: 28px;
  }
  .close {
    cursor: pointer;
  }
`;

type FileFormat =
  | ".gif"
  | ".jpg"
  | ".png"
  | ".pdf"
  | ".mp3"
  | ".wav"
  | ".flac"
  | ".fla"
  | "audio/*"
  | "video/*"
  | "image/*"
  | ".txt"
  | ".jpeg"
  | ".zip"
  | ".doc"
  | ".docx"
  | ".hwp"
  | ".hwpx"
  | ".txt"
  | ".mp4"
  | ".xls"
  | ".xlsx"
  | ".csv";

interface IUploadFileProps {
  format: FileFormat[];
  fileName: string | undefined;
  src: string | undefined;
  keyId?: string;
  onChange: (file: File, url: string) => void;
  onDelete?: () => void;
}
const UploadFile: React.FC<IUploadFileProps> = ({
  format,
  fileName,
  src,
  keyId,
  onChange,
  onDelete,
}) => {
  const alertModal = useSetRecoilState(AlertModal);
  const fileEvent = useRef<any>(null);

  const handleFileChange = async (e: any) => {
    if (format && format.length) {
      let fileName = e.target.files[0].name;
      const type = fileName.slice(fileName.lastIndexOf(".")).toLowerCase();
      if (format.includes(type)) {
        if (onChange) {
          const url = await getPreviewUrl(e.target.files[0]);
          onChange(e.target.files[0], url);
        }
      } else {
        alertModal({
          message: `${format.join(",")} 파일 형식만 업로드 가능합니다`,
        });
      }
    } else {
      if (onChange) {
        const url = await getPreviewUrl(e.target.files[0]);
        onChange(e.target.files[0], url);
      }
    }
    e.target.value = "";
  };

  const getPreviewUrl = (fileBlob: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileBlob);
    return new Promise<string>((resolve) => {
      reader.onload = () => {
        resolve(String(reader.result));
      };
    });
  };

  return (
    <UploadWrapper>
      <Button
        width="78px"
        height="28px"
        onClick={() => fileEvent.current.click()}
      >
        파일찾기
      </Button>
      {src && (
        <UploadItem>
          <img src={src} alt="emoji" />
          <span>{fileName}</span>
          <IconClose className="close" onClick={onDelete} />
        </UploadItem>
      )}
      <input
        ref={fileEvent}
        accept={format ? format.join(", ") : ""}
        type="file"
        onChange={handleFileChange}
        hidden
        id={"event_" + keyId}
      />
    </UploadWrapper>
  );
};

export default UploadFile;
