import Dropdown from "Component/Admin/Dropdown";

interface IRowDropdownField {
    keyId: string;
    title: string;
    items: { id: number; label: string; }[];
    value: number;
    onChange: (keyId: string, value: number) => void;
}

const RowDropdownField: React.FC<IRowDropdownField> = ({keyId, title, items, value, onChange}) => {
    const handleChange = (value: number) => {
        if (onChange) {
          onChange(keyId, value);
        }
      };
  return (
    <div className="row">
      <div className="item-key">{title}</div>
      <div className="item-value">
        <Dropdown
          items={items}
          width={200}
          value={value}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default RowDropdownField;
