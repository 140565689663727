
import { Dialog } from "@mui/material";
import Button from "Component/Admin/Button";
import { Modals } from "Component/Modules/Store/Modal.recoil";
import react, { useEffect, useState } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";

interface IModalConfirmProps {
    key: string;
    message?: string;
    escapable?: boolean;
    callback?: ()=>void;
}

const ModalConfirm: react.FC<IModalConfirmProps> = ({key, message, escapable, callback}) => {
    const [visible, setVisible] = useState(false);
    const myValue = useRecoilValue(Modals);
    const reset = useResetRecoilState(Modals)

    useEffect(()=>{
        setVisible(true);
    }, [])
    
    const handleOk = () => {
        setVisible(false);
        reset();
        if(callback){
            callback();
        }
        if(myValue[0].callback) {
            myValue[0].callback()
        }
    }

    const handleClose = () => {
        setVisible(false);
        reset();
    }

    return (
        <Dialog 
            open={visible}
            onClose={handleClose}
            className="alert-modal"
        >
            <div>{message}</div>
            <div className="button-area">
                <Button color="white" onClick={handleOk}>{myValue[0].okLabel ? myValue[0].okLabel : "확인"}</Button>
                <Button onClick={handleClose}>{myValue[0].closeLabel ? myValue[0].closeLabel : "취소"}</Button>
            </div>
        </Dialog>
    )
}

export default ModalConfirm;