import React, { useEffect, useRef } from "react";
import { DataGrid, GridColumns, GridSortItem } from "@mui/x-data-grid";
import Checkbox from "Component/Admin/CheckboxLabel/Checkbox";
import "./DataGrid.scss";
import { Tracing } from "trace_events";

interface ICustomDataGridProps {
    className?: string;
    isCheckBox: boolean;
    data: any[];
    columns: GridColumns<any>;
    limit: number;
    total: number;
    renderButtonArea?: () => React.ReactNode;
    onSelectionRows?: (list: any) => void;
    initialState?: any;
}
const CustomDataGrid: React.FC<ICustomDataGridProps> = ({ className, isCheckBox, data, columns, limit, total, renderButtonArea, onSelectionRows, initialState }) => {
    const ROW_HEIGHT = 56;

    const handleSelectionModelChange = (ids: any) => {
        const selectedIDs = new Set(ids);
        const selectedRowData = data.filter((row) => selectedIDs.has(row.id)).map((e) => e.id);
        if (onSelectionRows) {
            onSelectionRows(selectedRowData);
        }
    };

    useEffect(() => {
        const scrollBar = document.querySelector(".MuiDataGrid-virtualScroller");
        if (scrollBar) {
            scrollBar.scrollTop = 0;
        }
    }, [data]);

    return (
        <div className="data-grid-wrapper">
            <div className="search-result">
                <div className="search-result-text">
                    Total <span>{total}</span>
                </div>
                {renderButtonArea && renderButtonArea()}
            </div>

            {/* <div style={{ height: "100%", width: "100%" }}>
                <div style={{ display: "flex", height: "100%" }}>
                    <div style={{ flexGrow: 1 }}> */}
            <DataGrid
                autoHeight
                rows={data}
                columns={columns}
                checkboxSelection={isCheckBox}
                rowHeight={ROW_HEIGHT}
                pageSize={limit}
                disableColumnMenu
                hideFooter
                components={{
                    BaseCheckbox: Checkbox,
                }}
                onSelectionModelChange={handleSelectionModelChange}
                initialState={initialState}
            />
        </div>
        //         </div>
        //     </div>
        // </div>
    );
};

export default CustomDataGrid;
