import React,{ useEffect, useState }  from 'react';
import TextUnderline from '../../Component/TextUnderline/TextUnderline';
import useWindowSize from '../../Utils/useWindowSize/useWindowSize';
import { useLocation, Link } from 'react-router-dom';

import './HeadMore.scss';
function HeadMore(props:any) {
    
    const {Title,Decs} = props

    let leftHead 
    const { width, height } = useWindowSize();
    
    

    if(Title) {
        leftHead = <div className="head-more_title">
                        {Title}
                    </div>
    } else if (Decs){
        leftHead = <div className="head-more_decs">
                        {
                            <>
                                {Decs}
                            </>
                        }
                    </div>
    } else{
        leftHead = ""
    }

    const [styleInfoTitle, setStyleInfoTitle] = useState<any>({
        fontSize: `14px`,
        fontFamily: '$montserrat',
        marginRight: '5px',
        fontWeight: '600',
        lineHeight: '0.7',
        color: '#111111',
    });
    const [borderHeightInfoTitle, setBorderHeightTitle] = useState<number>(2);

   
    return (
        <div className= "head-more">
            {leftHead}
            <div className="head-more_link">
                <Link className="logo" to="/works">
                    <TextUnderline bottom={0} style={styleInfoTitle} borderheight={borderHeightInfoTitle}>
                        More
                    </TextUnderline>
                </Link>
            </div>

        </div>
        
    );
}

export default HeadMore;
