import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import "./SideMenu.scss";
import { resetUserToken } from "Component/Modules/Store/Cookie";
import { RouterString } from "Component/Modules/routesString";
import { IRouteMenu } from "Component/Modules/Utils/Config";
import { useRecoilValue, useResetRecoilState } from "recoil";
import {
  AuthenticatedState,
  UserAccountState,
} from "Component/Modules/Store/Common.recoil";
import Button from "Component/Admin/Button";
import { GetStyledCompImg } from "Component/Modules/Utils/Function";
import OneButtonDialog from "Component/Admin/Dialog/OneButtonDialog";

interface ISideLeftMenuProps {
  children: any;
  routers: IRouteMenu[];
}
const SideLeftMenu: React.FC<ISideLeftMenuProps> = ({ children, routers }) => {
  const [isLogoutOpen, setLogoutOpen] = useState(false);
  const userAccount = useRecoilValue(UserAccountState);
  const resetAuthenticated = useResetRecoilState(AuthenticatedState);
  const { pathname } = useLocation();
  const history = useHistory();

  const getCurActived = () => {
    const activeArr = routers.filter((item: any) => {
      if (item.isMainTab && (pathname + "/").indexOf(item.path + "/") > -1) {
        return true;
      }
    });
    return activeArr && activeArr.length > 0 ? activeArr[0].path : null;
  };

  const handleLogout = () => {
    resetUserToken();
    resetAuthenticated();
    setLogoutOpen(false);
    history.push(RouterString.AdminLogin);
  };
  return (
    <div className="side-menu-wrapper">
      <div className="side-container">
        <div className="user-info-container">
          {userAccount && (
            <span className="user-name">{`Hello ${userAccount.name}`}</span>
          )}
          <div className="button-area">
            <Button color="white" onClick={() => setLogoutOpen(true)}>
              Logout
            </Button>
          </div>
        </div>
        {Array.isArray(routers) &&
          routers
            .filter((item) => {
              const curActived = getCurActived();
              if (!curActived) {
                return true;
              }
              if (
                !item.isMainTab &&
                (item.path + "/").indexOf(curActived) <= -1
              ) {
                return false;
              }
              return true;
            })
            .map((item, index) => {
              const includePath =
                (pathname + "/").indexOf(item.path + "/") > -1;
              // const includePath = pathname === item.path;
              return (
                <div
                  key={index}
                  className={`menu-item ${includePath ? "active" : ""} ${
                    item.isMainTab ? "main" : "sub"
                  }`}
                >
                  <Link to={item.path}>
                    <div className={item.isMainTab ? "main-tab" : "sub-tab"}>
                      {item.deactiveImg && (
                        <img
                          className="img-deactive"
                          src={GetStyledCompImg(item.deactiveImg)}
                          alt=""
                        />
                      )}
                      {item.activeImg && (
                        <img
                          className="img-active"
                          src={GetStyledCompImg(item.activeImg)}
                          alt=""
                        />
                      )}
                      <span>{item.title}</span>
                    </div>
                  </Link>
                  {includePath && !item.isMainTab && (
                    <div className="right-active"></div>
                  )}
                </div>
              );
            })}
      </div>
      <div className="section">{children}</div>
      <OneButtonDialog
        open={isLogoutOpen}
        onClose={() => setLogoutOpen(false)}
        onClick={handleLogout}
        dialogBodyText1={"로그아웃 되었습니다."}
      />
    </div>
  );
};

export default SideLeftMenu;
