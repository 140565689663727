import { RouterString } from "Component/Modules/routesString";

export type RouteMenuKey = "ALL" | "GRAPHIC_DESIGN" | "FINE_ART" | "CONCEPT_ART" | "MV_TITLE" | "ADVERTISING";

type RouterMenuType = {
    [key in RouteMenuKey]: {
        key: "all" | "graphic design" | "fine art" | "concept art" | "M/V & Title" | "advertising";
        title: string;
        path: string;
    };
};

export const ROUTER_MENU: RouterMenuType = {
    ALL: { key: "all", title: "All", path: RouterString.WorkAll },
    FINE_ART: {
        key: "fine art",
        title: "Fine Art",
        path: RouterString.WorkFineArt,
    },
    GRAPHIC_DESIGN: {
        key: "graphic design",
        title: "Graphic Design",
        path: RouterString.WorkGraphicDesign,
    },
    CONCEPT_ART: {
        key: "concept art",
        title: "Concept Art",
        path: RouterString.WorkConceptArt,
    },
    MV_TITLE: {
        key: "M/V & Title",
        title: "M/V & Title",
        path: RouterString.WorkMV,
    },
    ADVERTISING: {
        key: "advertising",
        title: "Advertising",
        path: RouterString.WorkAdvertising,
    },
};
