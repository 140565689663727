import React from "react";
import Skeleton from '@mui/material/Skeleton';
import "./Skeleton.scss"
interface Props {
    variant?: "text" | "rounded" | "rectangular" | "circular" | undefined
    width?: number | any
    height?: number | any
    type?:string
}
const SkeletonCustom = ({ variant,width,height,type }: Props) => {
    return  <Skeleton className={`skeleton-custome ${type}`} style={{background: '#F4F5F7'}} variant={variant} width={width || '100%' } height={height} />;
};

export default SkeletonCustom;
