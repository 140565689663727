import { CSSProperties } from "react";
import { DraggingStyle, NotDraggingStyle } from "react-beautiful-dnd";

export const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export const getItemStyle = (isDragging: boolean, draggableStyle?: DraggingStyle | NotDraggingStyle) => {
    return {
        userSelect: "none",
        padding: "5px 5px",
        background: isDragging ? "lightgreen" : "transparent",
        ...draggableStyle,
    } as CSSProperties;
};
