
import { eModalType } from "Component/Modules/Store/Modal.recoil";
import react, { useEffect, useState } from "react";
import ModalAlert from "./ModalAlert";
import ModalConfirm from "./ModalConfirm";
import ModalContainer from "./ModalContainer";

interface IModalProps {

}

const CustomModals:react.FC<IModalProps> = () => {

    return (
        <ModalContainer >
            <ModalAlert key={eModalType.Alert} escapable/>
            <ModalConfirm key={eModalType.Confirm}/>
        </ModalContainer>
    )
}

export default CustomModals;