import { AxiosRequestConfig } from "axios";
import { getUserToken } from "Component/Modules/Store/Cookie";
import { IResponse, post, VER, get,put, destroy, patch  } from "../AxiosUtil";

const GET_LIST_ACCOUNT = VER + "/account";
const CREAT_ACCOUNT = VER + "/account";
const GET_DETAIL_ACCOUNT = VER + "/account/:id";
const UPDATE_ACCOUNT = VER + "/account/:id";
const DELETE_ACCOUNT = VER + "/account";
const CHANGE_PASSWORD = VER + "/account/change-pw/:id";

class AccountService {
    GetAccountList = async (
        data: any,
        config?: AxiosRequestConfig
      ): Promise<IResponse> => {
        try {
          const token = getUserToken();
          const response = await get(GET_LIST_ACCOUNT, {
            ...config,
            params: data,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          return response.data;
        } catch (e: any) {
          throw e;
        }
      };
    
    GetAccount = async (
    id: number,
    config?: AxiosRequestConfig
    ): Promise<IResponse> => {
    try {
        const token = getUserToken();
        const response = await get(GET_DETAIL_ACCOUNT.replace(":id", String(id)), {
        ...config,
        params: null,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        });
        return response.data;
    } catch (e: any) {
        throw e;
    }
    };
    
    CreateAccount = async (
    req: {username: string, password: string, name: string},
    config?: AxiosRequestConfig
    ): Promise<IResponse> => {
    try {
        const token = getUserToken();
        const body = {
        username: req.username,
        password: req.password,
        name : req.name
    };

        const response = await post(CREAT_ACCOUNT,body, {
        ...config,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        });
        return response.data;
    } catch (e) {
        throw e;
    }
    };
    UpdateAccount = async (
    req: {id: number, name: string},
    config?: AxiosRequestConfig
    ): Promise<IResponse> => {
        const body = {
            id: req.id,
            name: req.name,
        }
        try {
            const response = await put(UPDATE_ACCOUNT.replace(":id", String(req.id)),body, {
                ...config,
                headers: { Authorization: `Bearer ${getUserToken()}` },
            });
            return response.data;
            } catch (e) {
            throw e;
            }
    }
    changePasswordAccount = async (
        req: {id: number, newPassword: string, confirmPassword: string},
        config?: AxiosRequestConfig): Promise<IResponse> => {
        const body = {
            newPassword: req.newPassword,
            confirmPassword: req.confirmPassword,
        }
        try {
            const response = await patch(CHANGE_PASSWORD.replace(":id", String(req.id)),body, {
                ...config,
                headers: { Authorization: `Bearer ${getUserToken()}` },
            });
            return response.data;
            } catch (e) {
            throw e;
            }
    }
    deleteAdmin = async (req: { ids: number[] }, config?: AxiosRequestConfig) => {
        try {
            const params = {
                ids: req.ids
            };
            const response = await destroy(DELETE_ACCOUNT, {
                ...config,
                data: params,
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            });
    
            return response.data;
        } catch (e) {
            throw e;
        }
    }
}

export default new AccountService();
