import UploadFile from "Component/Admin/UploadFile";

interface IRowUploadField {
    keyId: string;
    title: string;
    subTitle: string;
    file?: File | null;
    url?: string;
    tip?: string;
    onChange: (key: string, file: File, url: string) => void;
    onDelete: (key: string) => void;
}

const RowUploadField: React.FC<IRowUploadField> = ({ keyId, title, subTitle, file, url, tip, onChange, onDelete }) => {
    const handleChange = (_file: File, _url: string) => {
        if (onChange) {
            onChange(keyId, _file, _url);
        }
    };
    const handleDelete = () => {
        if (onDelete) {
            onDelete(keyId);
        }
    };
    return (
        <div className="row row-70">
            <div className="item-key" style={{ display: "flex", flexDirection: "column" }}>
                {title}
                <div style={{ fontSize: "10px" }}>{subTitle}</div>
            </div>
            <div className="item-value">
                <UploadFile format={[".png", ".jpeg", ".jpg"]} fileName={file?.name} src={url} onChange={handleChange} onDelete={handleDelete} keyId={keyId} />
                {tip && <div className="tip">{`* ${tip}`}</div>}
            </div>
        </div>
    );
};

export default RowUploadField;
