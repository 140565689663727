import { RouterString } from "Component/Modules/routesString";
import { Link, useHistory, useLocation } from "react-router-dom";
import "./Header.scss";
import routes from "./Routes";

function withHeader(Component: any) {
  return function render(props: any) {
    return (
      <>
        <Header menus={routes} />
        <Component {...props} />
      </>
    );
  };
}

interface IMenuProp {
  to?: string;
  activeMenu: string;
  menuName: string;
}
interface IHeaderProps {
  menus: IMenuProp[];
  children?: any;
}
const Header: React.FC<IHeaderProps> = ({ menus }) => {
  const { pathname } = useLocation();
  const history = useHistory();

  //권한 별 메인 대시보드 페이지 이동
  const goMain = () => {
    history.push(RouterString.Main);
  };

  return (
    <header
      id="header"
      className={`header-wrapper`}
      // "header-active"
    >
      <div
        // style={{ top: `${headerPos}px` }}
        className={`header-content`}
      >
        <div className="header-logo" onClick={goMain}>
          {/* <img src={logo} alt="" /> */}
          <span>XUXU</span>
        </div>
        <div className="content-container">
          {menus.map((item, index) => {
            const isActive = pathname.indexOf(item.activeMenu) > -1;
            return (
              <div
                key={`header_item_${index}`}
                className={`top-menu ${isActive && "active"}`}
              >
                {item.to ? (
                  <Link to={item.to}>{item.menuName}</Link>
                ) : (
                  <a onClick={() => alert("준비중입니다")}>{item.menuName}</a>
                )}
                {isActive && <div className="bottom-active" />}
              </div>
            );
          })}
        </div>
      </div>
    </header>
  );
};

export default withHeader;
