import Button from "Component/Admin/Button";
import InputField from "Component/Admin/Input/InputField";
import PageTitle from "Component/Admin/PageTitle";
import withSideMenu from "Component/Admin/SideMenu";
import { AlertModal } from "Component/Modules/Store/Modal.recoil";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import routes from "../Routes";
import "Pages/Admin/Common.style.scss";
import PasswordChangePopup from "Popups/ChangePasswordPopup";
import AccountService from "Component/Modules/API/Services/Account.service";
import { RouterString } from "Component/Modules/routesString";
import { UserAccountState } from "Component/Modules/Store/Common.recoil";
import Loading from "Component/Admin/Loading";

interface IPutAccounts {
  isRegist: boolean;
}

interface IReqCreateAccount {
  username: string;
  password: string;
  name: string;
}
interface IGetDetailWork {
  thumbnailUrl?: string;
  imageUrl?: string;
}

const PutAccounts: React.FC<IPutAccounts> = ({ isRegist }) => {
  const [request, setRequest] = useState<any>({
    username: "",
    name: "",
  });

  const [detail, setDetail] = useState<IGetDetailWork>({
    thumbnailUrl: "",
    imageUrl: "",
  });
  const userAccountState = useRecoilValue(UserAccountState);
  const [isOpenChangePassword, setIsOpenChangePassword] = useState(false);
  const alertModal = useSetRecoilState(AlertModal);
  const [loading, setLoading] = useState<boolean>(false);

  const history = useHistory();
  const location = useLocation();
  const state: any = location.state;

  useEffect(() => {
    if (!isRegist && state) {
      getAccountDetail(state.id);
    }
  }, []);

  const getAccountDetail = async (id: number) => {
    setLoading(true);
    const response = await AccountService.GetAccount(id);
    if (response && response.code === "OK200") {
      const { id, username, name } = response.data;
      setRequest({
        username: username,
        name: name,
        id: id,
      });
      setLoading(false);
    }
  };

  const handleClickCancel = () => {
    history.goBack();
  };

  const handleClickUpdate = async () => {
    try {
      if (request.id > 0) {
        setLoading(true);
        const response = await AccountService.UpdateAccount({
          id: request.id,
          name: request.name,
        });
        alertModal({
          message: "Changes are applied.",
          callback: () => {
            setLoading(false);
            history.push(RouterString.AdminAccounts);
          },
        });
      }
    } catch (error: any) {
      alertModal({
        message: error.response.data.message,
        callback: () => setLoading(false),
      });
    }
  };

  const handleClickRegist = async () => {
    try {
      setLoading(true);
      const response = await AccountService.CreateAccount({
        name: request.name,
        username: request.username,
        password: request.password,
      });
      setLoading(false);
      history.push(RouterString.AdminAccounts);
    } catch (error: any) {
      alertModal({
        message: error.response.data.message,
        callback: () => setLoading(false),
      });
    }
  };

  const handleChangeInput = (key: string, value: any) => {
    setRequest({ ...request, [key]: value });
  };

  const renderTitle = () => {
    const title = isRegist ? "Create Account" : "Update Account";
    return <PageTitle title={title} />;
  };

  const renderButtonArea = () => {
    if (isRegist) {
      return (
        <div className="button-area">
          <Button width="100px" onClick={handleClickRegist}>
            Create
          </Button>
          <Button width="100px" color="white" onClick={handleClickCancel}>
            Cancel
          </Button>
        </div>
      );
    } else {
      return (
        <div className="button-area">
          <Button width="100px" onClick={handleClickUpdate}>
            Update
          </Button>
          <Button width="100px" color="white" onClick={handleClickCancel}>
            Cancel
          </Button>
        </div>
      );
    }
  };

  return (
    <Loading spinning={loading}>
      <div className="admin-works-put-wrapper">
        {renderTitle()}
        <div className="detail-body">
          <div className="input-grid-area">
            <div className="row">
              <div className="item-key">{`ID *`}</div>
              <div className="item-value">
                {isRegist ? (
                  <InputField
                    text={request.username}
                    placeholder="ex: asdf1234"
                    onChange={(value) => handleChangeInput("username", value)}
                  />
                ) : (
                  <h3>{request.username}</h3>
                )}
              </div>
            </div>
            <div className="row">
              <div className="item-key">{`Password *`}</div>
              <div className="item-value">
                {isRegist ? (
                  <InputField
                    text={request.password}
                    placeholder="ex: qwer1234"
                    onChange={(value) => handleChangeInput("password", value)}
                    type="password"
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "100px",
                    }}
                  >
                    <div>*******</div>
                    <Button
                      width="200px"
                      color="default"
                      onClick={() => setIsOpenChangePassword(true)}
                    >
                      Change Password
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="item-key">{`Name  *`}</div>
              <div className="item-value">
                <InputField
                  text={request.name}
                  placeholder="ex: Jo young kyoung"
                  onChange={(value) => handleChangeInput("name", value)}
                />
              </div>
            </div>
          </div>
        </div>
        <PasswordChangePopup
          id={request.id}
          open={isOpenChangePassword}
          setLoading={setLoading}
          onClose={() => setIsOpenChangePassword(false)}
        />
        {renderButtonArea()}
      </div>
    </Loading>
  );
};

export default withSideMenu(PutAccounts, routes);
