import React from "react";
import DatePicker from "react-datepicker";
import icoSwapRight from "Assets/images/Admin/Icons/ico-swap-right.svg";
import "./RangePicker.scss";

interface IRangePickerProps {
	startDate?: Date;
	endDate: Date;
	onChange?: (type: "start" | "end", date: Date | null) => void;
}
const RangePicker: React.FC<IRangePickerProps> = ({ startDate, endDate, onChange }) => {
	const handleChange = (type: "start" | "end", date: Date | null) => {
		if (onChange) {
			onChange(type, date);
		}
	};

	return (
		<div className="range-picker">
			<DatePicker
				dateFormat={"yyyy-MM-dd"}
				selected={startDate}
				onChange={date => handleChange("start", date)}
				selectsStart
				startDate={startDate}
				endDate={endDate}
			/>
			<img src={icoSwapRight} width={14}/>
			<DatePicker
				dateFormat={"yyyy-MM-dd"}
				selected={endDate}
				onChange={date => handleChange("end", date)}
				selectsEnd
				startDate={startDate}
				endDate={endDate}
				minDate={startDate}
			/>
		</div>
	);
};

export default RangePicker;
