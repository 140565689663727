import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import { ISelectBox } from "../API/Interfaces/Common.interface";

const { persistAtom } = recoilPersist();

export const CategoryCode = atom({
	key: "category",
	default: [{
		id: 0,
		label: "",
		code: ""
	} as ISelectBox],
	// effects_UNSTABLE: [persistAtom] // Phrases stored in local storage.
});

export const ClassificationCode = atom({
	key: "classification",
	default: [{
		id: 0,
		label: "",
		code: ""
	} as ISelectBox],
	// effects_UNSTABLE: [persistAtom] // Phrases stored in local storage.
});
export const LangCode = atom({
	key:"lang",
	default:"en"
})