import { AxiosRequestConfig } from "axios";
import { getUserToken } from "Component/Modules/Store/Cookie";
import { get, IResponse, post, VER, destroy, put } from "../AxiosUtil";
import { IReqCreateWork, IReqUpdateWork, IReqUpdateWorksOrder, IReqWork, IReqWorkForOrder } from "../Interfaces/Work.interface";

const GET_WORK_LIST = VER + "/work";
const GET_WORK_LIST_FOR_ORDER = VER + "/work/order";
const GET_WORK = VER + "/work/:id";
const POST_CREATE_WORK = VER + "/work";
const PUT_WORK_LIST_FOR_ORDER = VER + "/work/order";
const PUT_UPDATE_WORK = VER + "/work/:id";
const DELETE_WORK = VER + "/work";

class WorkService {
    GetWorkList = async (data: IReqWork, config?: AxiosRequestConfig): Promise<IResponse> => {
        try {
            const token = getUserToken();
            const response = await get(GET_WORK_LIST, {
                ...config,
                params: data,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (e: any) {
            throw e;
        }
    };
    GetWorkListForOrder = async (data: IReqWorkForOrder, config?: AxiosRequestConfig): Promise<IResponse> => {
        try {
            const token = getUserToken();
            const response = await get(GET_WORK_LIST_FOR_ORDER, {
                ...config,
                params: data,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (e: any) {
            throw e;
        }
    };

    GetWork = async (id: number, config?: AxiosRequestConfig): Promise<IResponse> => {
        try {
            const token = getUserToken();
            const response = await get(GET_WORK.replace(":id", String(id)), {
                ...config,
                params: null,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (e: any) {
            throw e;
        }
    };

    _GetFormData = (data: IReqCreateWork) => {
        const formData = new FormData();
        formData.append("title_kor", data.titleKor);
        formData.append("title_eng", data.titleEng);
        formData.append("short_title_kor", data.shortTitleKor);
        formData.append("short_title_eng", data.shortTitleEng);

        if (data.descriptionKor !== undefined) {
            formData.append("description_kor", data.descriptionKor);
        }
        if (data.descriptionEng !== undefined) {
            formData.append("description_eng", data.descriptionEng);
        }
        formData.append("category_id", String(data.categoryId));
        formData.append("classification_id", String(data.classificationId));
        if (data.year !== undefined) {
            formData.append("year", data.year);
        }
        if (data.videoUrl !== undefined) {
            formData.append("video_url", data.videoUrl);
        }
        return formData;
    };
    PostCreateWork = async (data: IReqCreateWork, config?: AxiosRequestConfig): Promise<IResponse> => {
        try {
            const token = getUserToken();
            const formData = this._GetFormData(data);
            formData.append("thumbnail", data.thumbnailFile!);
            formData.append("sub_thumbnail", data.subThumbnailFile!);

            if (data.imageFileArr) {
                data.imageFileArr.forEach((file) => file && formData.append("image", file));
            }

            const response = await post(POST_CREATE_WORK, formData, {
                ...config,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            });
            return response.data;
        } catch (e) {
            throw e;
        }
    };
    UpdateWork = async (data: IReqUpdateWork, config?: AxiosRequestConfig): Promise<IResponse> => {
        try {
            const formData = this._GetFormData(data);
            formData.append("id", String(data.id));
            if (data.thumbnailFile) {
                formData.append("thumbnail", data.thumbnailFile);
            }
            if (data.subThumbnailFile) {
                formData.append("sub_thumbnail", data.subThumbnailFile);
            }
            if (data.upsert) {
                formData.append(
                    "upsert",
                    JSON.stringify(
                        data.upsert.map((item) => ({
                            id: item.id,
                            fileName: item.imageFile?.name,
                        }))
                    )
                );
                data.upsert.forEach((item) => {
                    if (item && item.imageFile) {
                        formData.append("image", item.imageFile);
                    }
                });
            }
            if (data.deleteIds && data.deleteIds.length) {
                formData.append("delete_ids", JSON.stringify(data.deleteIds));
            }

            const response = await put(PUT_UPDATE_WORK.replace(":id", String(data.id)), formData, {
                ...config,
                headers: { Authorization: `Bearer ${getUserToken()}` },
            });
            return response.data;
        } catch (e) {
            throw e;
        }
    };

    UpdateWorksOrder = async (data: IReqUpdateWorksOrder, config?: AxiosRequestConfig): Promise<IResponse> => {
        try {
            const response = await put(PUT_WORK_LIST_FOR_ORDER, data, {
                ...config,
                headers: { Authorization: `Bearer ${getUserToken()}` },
            });
            return response.data;
        } catch (e) {
            throw e;
        }
    };

    DeleteWork = async (req: { ids: number[] }, config?: AxiosRequestConfig) => {
        try {
            const params = {
                ids: req.ids,
            };
            const response = await destroy(DELETE_WORK, {
                ...config,
                data: params,
                headers: {
                    Authorization: `Bearer ${getUserToken()}`,
                },
            });

            return response.data;
        } catch (e) {
            throw e;
        }
    };
}

export default new WorkService();
