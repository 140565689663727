import InputField from "Component/Admin/Input/InputField";
import "Pages/Admin/Common.style.scss";

interface IRowTextField {
  keyId: string;
  title: string;
  text: string;
  placeholder?: string;
  onChange: (keyId: string, value: string) => void;
}
const RowTextField: React.FC<IRowTextField> = ({
  keyId,
  title,
  text,
  placeholder,
  onChange,
}) => {
  const handleChange = (text: string) => {
    if (onChange) {
      onChange(keyId, text);
    }
  };
  return (
    <div className="row">
      <div className="item-key">{title}</div>
      <div className="item-value">
        <InputField
          text={text}
          placeholder={placeholder}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default RowTextField;
