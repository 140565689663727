import React from "react";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import { ReactComponent as CheckBoxOn } from "Assets/images/Admin/Icons/ico-check-on.svg";
import { ReactComponent as CheckBoxOff } from "Assets/images/Admin/Icons/ico-check-off.svg";
import styled from "styled-components";

const CheckOn = styled(CheckBoxOn)`
	color: var(--color-main); //#FF671D
`;

const CheckboxWrapper = React.forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
	return (
		<Checkbox
			inputRef={ref}
			icon={<CheckBoxOff />}
			checkedIcon={<CheckOn />}
			indeterminateIcon={<CheckBoxOff />}
			{...props}
		/>
	);
});

export default CheckboxWrapper;
