import { Dialog } from "@mui/material";
import Button from "Component/Admin/Button";
import { ReactComponent as IconClose } from "Assets/images/Admin/Icons/ico-close.svg";
import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import "./Popup.scss";
import InputField from "Component/Admin/Input/InputField";
import AccountService from "Component/Modules/API/Services/Account.service";
import { AlertModal } from "Component/Modules/Store/Modal.recoil";

interface IPasswordChangePopupProps {
  id: number;
  open: boolean;
  onClose?: () => void;
  setLoading: any;
}
const PasswordChangePopup: React.FC<IPasswordChangePopupProps> = ({
  id,
  open,
  onClose,
  setLoading,
}) => {
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPass] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const alertModal = useSetRecoilState(AlertModal);
  const [isReq, setReq] = useState<boolean>(false);

  const handleChangePassword = async () => {
    if (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(newPassword)) {
      setErrorMessage("Password rule is invalid.");
      return;
    }
    if (newPassword.localeCompare(confirmPassword) === 1) {
      setErrorMessage("Password does not match.");
      return;
    }
    setErrorMessage("");
    try {
      setLoading(true);
      setReq(true);
      const response = await AccountService.changePasswordAccount({
        id,
        newPassword,
        confirmPassword,
      });
      alertModal({
        message: "Password changed.",
        callback: () => {
          setReq(false);
          setLoading(false);
          if (onClose) {
            onClose();
          }
        },
      });
    } catch (err: any) {
      alertModal({
        message: err.response.data.message,
        callback: () => {
          setReq(false);
          setLoading(false);
        },
      });
    }
  };
  useEffect(() => {
    setErrorMessage("");
    setNewPassword("");
    setConfirmPass("");
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} className="password-change-popup">
      <div className="title-area">
        <span>Change Password</span>
        <IconClose onClick={onClose} />
      </div>
      <div className="content-area">
        <div className="content">Please enter a new password</div>
        <div className="row">
          <div className="row-key">New PW</div>
          <div className="row-input">
            <InputField
              type="password"
              text={newPassword}
              placeholder="Enter at least 8 characters, English case and number"
              onChange={(text) => setNewPassword(text)}
            />
          </div>
        </div>
        <div className="row">
          <div className="row-key">Confirm PW</div>
          <div className="row-input">
            <InputField
              type="password"
              text={confirmPassword}
              placeholder=""
              onChange={(text) => setConfirmPass(text)}
            />
            {errorMessage && (
              <div className="row-error">{`※ ${errorMessage}`}</div>
            )}
          </div>
        </div>
      </div>
      <div className="button-area">
        <Button color="white" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleChangePassword} disabled={isReq}>
          Change
        </Button>
      </div>
    </Dialog>
  );
};

export default PasswordChangePopup;
